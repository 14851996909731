import React from 'react';

import { useTheme } from 'styled-components';
import { BiChevronRight } from 'react-icons/bi';
import { useHistory } from 'react-router-dom';
import { subMonths } from 'date-fns';
import truncateText from 'utils/truncateText';
import {
  DataSectionCardContainer as Container,
  DataSectionCardTableAmount as TableAmount,
  DataSectionCardTable as Table,
  DataSectionCardTablePercent as TablePercent,
  DataSectionCardTableDataName as TableDataName,
} from './styles';

interface DataSectionCardProps {
  title: string;
  subtitle?: string;
  columnText?: string;
  additionalText?: string;
  data: {
    name: string;
    amount: number;
    percent: number;
    isMoreBetter?: boolean;
    icd10?: string;
    sheet?: string;
  }[];
  colorizedPercent?: boolean;
  hasNavigation?: boolean;
}

export const DataSectionCard: React.FC<DataSectionCardProps> = ({
  title,
  subtitle,
  columnText,
  data,
  colorizedPercent = true,
  hasNavigation = true,
  additionalText,
}) => {
  const theme = useTheme();
  const history = useHistory();

  const getPercentColor = (percent: number, isMoreBetter = true) => {
    if (!colorizedPercent) {
      return theme.colors.graySecondary;
    }

    if (isMoreBetter) {
      if (percent < 0.2) {
        return theme.colors.danger1;
      }

      if (percent >= 0.5) {
        return theme.colors.success1;
      }

      return theme.colors.warning1;
    }

    if (percent < 0.3) {
      return theme.colors.success1;
    }

    if (percent >= 0.7) {
      return theme.colors.danger1;
    }

    return theme.colors.warning1;
  };

  function RedirectToWellfare(icd10?: string, sheet?: string) {
    const period = {
      startDate: subMonths(new Date(), 3),
      endDate: new Date(),
    };

    history.push({
      pathname: '/administration/well_fare',
      search: new URLSearchParams({
        ...(icd10 && { icd10 }),
        ...(sheet && { sheet }),
        startDate: period.startDate.toDateString(),
        endDate: period.endDate.toDateString(),
      }).toString(),
    });
  }

  return (
    <Container>
      <h1>{title}</h1>

      {subtitle && <h6>{subtitle}</h6>}

      <div>
        <Table>
          <thead>
            <tr>
              <th>{columnText}</th>
              <th>Qtd.</th>
              <th>% Total {additionalText && `${additionalText}`}</th>
            </tr>
          </thead>
          <tbody>
            {data
              .sort((a, b) => b.percent - a.percent)
              .map((item, index) => {
                const name = truncateText({ maxLength: 60, text: item.name });
                return (
                  <tr key={+index}>
                    <TableDataName>{name}</TableDataName>
                    <TableAmount>{item.amount}</TableAmount>
                    <TablePercent
                      color={getPercentColor(item.percent, item.isMoreBetter)}
                    >
                      {item.percent}%
                      {hasNavigation && (
                        <button
                          type="button"
                          onClick={() =>
                            RedirectToWellfare(item.icd10, item.sheet)
                          }
                        >
                          <BiChevronRight
                            size={18}
                            color={theme.colors.primary}
                          />
                        </button>
                      )}
                    </TablePercent>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};
