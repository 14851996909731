import React from 'react';
import { BarChart, QuestionCardContainer } from './styles';

interface QuestionCardProps {
  question: string;
  options: {
    id: string;
    description: string;
    value: number;
  }[];
}

export const QuestionCard: React.FC<QuestionCardProps> = ({
  question,
  options,
}) => {
  return (
    <QuestionCardContainer>
      <div className="questionContainer">
        <span>{question}</span>
        <div className="resultContainer">
          <span>
            {options.length < 10 ? `0${options.length}` : options.length}{' '}
            respostas
          </span>
        </div>
      </div>
      <div className="optionsContainer">
        <div className="optionsTextContainer">
          {options.map((option) => (
            <span key={option.id}>{option.description}</span>
          ))}
        </div>
        <div className="barChartContainer">
          {options.map((option) => (
            <div className="barItem" key={option.id}>
              <BarChart value={option.value} />
              <span className="percentageValueText">{option.value}%</span>
            </div>
          ))}
        </div>
      </div>
    </QuestionCardContainer>
  );
};
