import styled from 'styled-components';

export const PatientListContainer = styled.div`
  border-radius: 6px;
  background: #ffffff;

  padding: 21px 31px 19px 24px;

  grid-area: patientList;

  box-shadow: 0px 4px 10px 0px rgba(6, 92, 119, 0.05);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  #patients {
    border: none;
    width: 100%;
    table-layout: fixed;
  }

  tr:not(:last-child) {
    td {
      border-bottom: 1px solid #ebeff2;
    }
  }

  img {
    width: 20px;
    height: 20px;
  }

  #patients th {
    padding: 12px 0;
  }

  #patients thead th {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: #707683;
    text-align: start;
  }

  .patientName {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.01em;
    color: #333333;
  }

  .patientInfoColumn {
    padding: 12px 0;
  }

  .columnInfoText {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: #707683;
  }

  button {
    background: transparent;
    align-self: center;
    border: none;

    font-family: Raleway;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #2d9cdb;
  }
`;

export const AvatarContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  overflow: hidden;
`;

export const PatientInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
