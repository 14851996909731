import React from 'react';
import { ReactComponent } from 'assets/images/bullet-point.svg';

interface BulletPointProps {
  color?: string;
  size?: number;
  style?: React.CSSProperties;
}

export const BulletPoint: React.FC<BulletPointProps> = ({
  color,
  size = 6,
  style,
}) => {
  return (
    <ReactComponent color={color} height={size} width={size} style={style} />
  );
};
