import { api } from '../api';

interface ListPatientsRequestDTO {
  order: 'ASC' | 'DESC';
  orderType: 'Alphabetical' | 'TimePeriod';
  page: number;
  take: number;
  icd10Id?: string | null;
  sheetId?: string | null;
  patientName?: string;
  startDate?: string | null;
  endDate?: string | null;
}

interface Patient {
  patientId: string;
  sheetDate: Date;
  name: string;
  icd10Description: string;
}

export interface ListPatientResponseDTO {
  patients: Patient[];
  total: number;
}

export const listPatientsService = async ({
  order,
  orderType,
  page,
  take,
  icd10Id,
  sheetId,
  patientName,
  startDate,
  endDate,
}: ListPatientsRequestDTO): Promise<ListPatientResponseDTO> => {
  const { data } = await api.get<ListPatientResponseDTO>(
    `/patients/listPatients`,

    {
      params: {
        startDate,
        endDate,
        order,
        orderType,
        page,
        take,
        icd10Id,
        sheetId,
        patientName,
      },
    },
  );

  return data;
};
