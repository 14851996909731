import React from 'react';
import { CategoriesContainer, CategoryItem, Container, Header } from './styles';
import { useWellFareContext } from '../WellFareContext';

interface BoardCategory {
  id: number;
  name: string;
  isActive: boolean;
}

interface HealthDataBoardProps {
  boardCategories: BoardCategory[];
  onItemClick: (id: number) => void;
  activeItemId: number;
}

export const HealthDataBoard: React.FC<HealthDataBoardProps> = ({
  boardCategories,
  onItemClick,
  activeItemId,
}) => {
  const contextValue = useWellFareContext();
  return (
    <Container>
      <Header>
        <div className="textHeaderContainer">
          <span className="health_title">Dados de Saúde</span>
          {contextValue && (
            <span className="large_health_title">
              {contextValue.sheet.name}
            </span>
          )}
        </div>
        {contextValue && (
          <div className="resultContainer">
            <span className="resultText">
              {contextValue.sheet.patientCount} Respostas
            </span>
          </div>
        )}
      </Header>
      <CategoriesContainer>
        {boardCategories.map((category) => (
          <CategoryItem
            isActive={activeItemId === category.id}
            onClick={() => onItemClick(category.id)}
            className="categoryItem"
            key={category.id}
          >
            <span>{category.name}</span>
          </CategoryItem>
        ))}
      </CategoriesContainer>
    </Container>
  );
};
