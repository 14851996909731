import React, { useCallback, useEffect, useState } from 'react';
import {
  ListPatientResponseDTO,
  listPatientsService,
} from 'services/patient/listPatientsService';
import Spinner from 'components/Spinner';
import { DataSearchForm } from 'pages/Data/List/DataSearchForm';
import { useLocation } from 'react-router-dom';
import { GeneralResultContainer, FormContainer } from './styles';
import { GeneralData } from './GeneralData';
import { Insights } from './Insights';
import { PatientData } from './PatientData';
import { DataProgression } from './DataProgression';
import { PatientList } from './PatientList';
import { SmartBand } from './SmartBand';

export type PatientsData = ListPatientResponseDTO['patients'];

interface ListProps {
  order: 'ASC' | 'DESC';
  orderType: 'Alphabetical' | 'TimePeriod';
  page: number;
  take: number;
  patientName?: string;
  period?: {
    startDate: string;
    endDate: string;
  };
}

interface GeneralResultProps {
  onItemClick: (id: number) => void;
}

export const GeneralResult: React.FC<GeneralResultProps> = ({
  onItemClick,
}) => {
  const [patients, setPatients] = useState<PatientsData>();

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  const loadPatients = useCallback(async (listData: ListProps) => {
    const params = new URLSearchParams(location?.search);

    const icd10Id = params.get('icd10');
    const sheetId = params.get('sheet');
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');

    try {
      setIsLoading(true);
      const { patients: loadedPatients } =
        (await listPatientsService({
          ...listData,
          icd10Id,
          sheetId,
          startDate,
          endDate,
        })) || {};

      setPatients(loadedPatients);
    } catch {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadPatients({
      order: 'ASC',
      orderType: 'Alphabetical',
      page: 1,
      take: 4,
    });
  }, [loadPatients]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <GeneralResultContainer>
      <FormContainer>
        <DataSearchForm />
      </FormContainer>
      <GeneralData />
      <Insights />
      <PatientData />
      <DataProgression />
      <PatientList patients={patients} onItemClick={onItemClick} />
      <SmartBand />
    </GeneralResultContainer>
  );
};
