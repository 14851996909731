import React, { useState } from 'react';

import { abbreviateNumber } from 'utils/abbreviateNumber';
import { BulletPoint } from 'components/BulletPoint';
import { HorizontalBarChart } from 'components/HorizontalBarChart';
import useQuery from 'hooks/query';
import { useCurrentEffect } from 'lib/use-current-effect';
import { getPatientCountGroupedByAge } from 'services/patient';
import { GetPatientCountGroupedByAgeResponseDTO } from 'dtos/GetPatientCountGroupedByAgeResponseDTO';
import Spinner from 'components/Spinner';
import { DataSectionCardTable as Table } from '../DataSectionCard/styles';
import {
  ChartContainer,
  DataChartCardRightColumn as RightColumn,
  DataChartCardTd as Td,
  AgeChartTop as Top,
  AgeChartTotalArea as TotalArea,
} from './styles';

interface AgeChartProps {
  startDate: string;
  endDate: string;
}

export const AgeChart: React.FC<AgeChartProps> = ({ endDate, startDate }) => {
  const [data, setData] = useState<GetPatientCountGroupedByAgeResponseDTO>();
  const query = useQuery();

  useCurrentEffect(
    (isCurrent) => {
      getPatientCountGroupedByAge({
        icd10: query.get('icd10'),
        sheet: query.get('sheet'),
        start: startDate,
        end: endDate,
      }).then((response) => isCurrent() && setData(response));
    },
    [query, startDate, endDate],
  );

  if (!data) return <Spinner />;

  return (
    <ChartContainer>
      <Top>
        <TotalArea>
          <strong>{abbreviateNumber(data.total)}</strong>
          <p>pacientes</p>
        </TotalArea>
        <HorizontalBarChart
          data={[
            {
              name: 'Crianças',
              amount: data.child,
              color: '#DB3E5A',
            },
            {
              name: 'Jovens',
              amount: data.teen,
              color: '#EEB711',
            },
            {
              name: 'Adultos',
              amount: data.adult,
              color: '#2C7BE5',
            },
            {
              name: 'Idosos',
              amount: data.elder,
              color: '#6E46C3',
            },
          ]}
        />
      </Top>

      <div>
        <Table>
          <tbody>
            <tr>
              <Td>
                <BulletPoint color="#DB3E5A" style={{ marginRight: 6 }} />
                Crianças
              </Td>
              <RightColumn>
                {data.child} <span>Pacientes</span>
              </RightColumn>
            </tr>
            <tr>
              <Td>
                <BulletPoint color="#EEB711" style={{ marginRight: 6 }} />
                Jovens
              </Td>
              <RightColumn>
                {data.teen} <span>Pacientes</span>
              </RightColumn>
            </tr>
            <tr>
              <Td>
                <BulletPoint color="#2C7BE5" style={{ marginRight: 6 }} />
                Adultos
              </Td>
              <RightColumn>
                {data.adult} <span>Pacientes</span>
              </RightColumn>
            </tr>
            <tr>
              <Td>
                <BulletPoint color="#6E46C3" style={{ marginRight: 6 }} />
                Idosos
              </Td>
              <RightColumn>
                {data.elder} <span>Pacientes</span>
              </RightColumn>
            </tr>
          </tbody>
        </Table>
      </div>
    </ChartContainer>
  );
};
