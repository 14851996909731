import styled from 'styled-components';

export const InputDateWrapper = styled.div`
  display: grid;
  gap: 6px;
  flex-basis: 300px;
  flex-grow: 1;
`;

export const InputLabel = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.grayTertiary};
  font-size: 12px;
  font-weight: 500;
`;

export const InputDateContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  position: relative;
  width: 100%;
  height: 42px;

  border: 1px solid #e0e0e0;
  color: #828282;

  display: flex;
  align-items: center;
  padding: 0 12px;

  > :nth-child(1) {
    flex: 1;
  }
`;
