import React, { useMemo, useState } from 'react';
import { Container, BoardContainer } from './styles';
import DashboardTemplate from '../../../templates/Dashboard';
import MainAreasEnum from '../../../enums/MainAreasEnum';
import { HealthDataBoard } from './HealthDataBoard';
import { ElementPageTree } from '../../../components/PageTree/types';
import PageTree from '../../../components/PageTree';
import { WellFareContext } from './WellFareContext';
import { ScreenSelector } from './ScreenSelector';

export const WellFare: React.FC = () => {
  const [activeItemId, setActiveItemId] = useState(1);

  const boardCategories = [
    {
      id: 1,
      name: 'Resultado geral',
      isActive: false,
    },
    {
      id: 2,
      name: 'Resultado por paciente',
      isActive: false,
    },
    {
      id: 3,
      name: 'Questionários',
      isActive: false,
    },
    {
      id: 4,
      name: 'Evolução',
      isActive: false,
    },
    {
      id: 5,
      name: 'Análise avançada',
      isActive: false,
    },
  ];

  const onItemClick = (id: number) => {
    setActiveItemId(id);
  };

  const pages = useMemo<ElementPageTree[]>(
    () => [
      {
        name: 'Administração',
      },
      {
        name: 'Dados de Saúde',
      },
      {
        name: 'Bem Estar',
      },
    ],
    [],
  );

  return (
    <DashboardTemplate name={MainAreasEnum.MANAGEMENT}>
      <WellFareContext>
        <Container>
          <BoardContainer>
            <PageTree pages={pages} />
            <HealthDataBoard
              boardCategories={boardCategories}
              activeItemId={activeItemId}
              onItemClick={onItemClick}
            />
          </BoardContainer>
          <ScreenSelector
            selectedScreenId={activeItemId}
            onItemClick={onItemClick}
          />
        </Container>
      </WellFareContext>
    </DashboardTemplate>
  );
};
