import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { BsChevronDown } from 'react-icons/bs';
import { InputDateRange } from 'components/InputDateRange';
import InputText from 'components/InputText';
import {
  ListPatientResponseDTO,
  listPatientsService,
} from 'services/patient/listPatientsService';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import {
  InputContainer,
  LeftInfo,
  ListInfoContainer,
  Order,
  ResultPerPatientContainer,
  RightInfo,
  SearchButton,
  SearchButtonWrapper,
  SearchFilterContainer,
  SearchIcon,
  Select,
} from './styles';
import { ReactComponent as ArrowUpDownIcon } from '../../../../assets/images/arrowupdown.svg';
import { PatientResultTable } from './components/PatientResultTable';

export type PatientsData = ListPatientResponseDTO['patients'];

interface ListProps {
  order: 'ASC' | 'DESC';
  orderType: 'Alphabetical' | 'TimePeriod';
  page: number;
  take: number;
  patientName?: string;
  period?: {
    startDate: string | null;
    endDate: string | null;
  };
}

interface FormProps {
  name: string;
  period: {
    startDate: string;
    endDate: string;
  };
}

const options = [
  {
    label: 'Ordem Alfabética (A-Z)',
    value: { orderType: 'Alphabetical', order: 'ASC' },
  },
  {
    label: 'Ordem Alfabética (Z-A)',
    value: { orderType: 'Alphabetical', order: 'DESC' },
  },
  { label: 'Mais antigo', value: { orderType: 'TimePeriod', order: 'ASC' } },
  {
    label: 'Mais recente',
    value: { orderType: 'TimePeriod', order: 'DESC' },
  },
];

type OrderOptions = Pick<ListProps, 'order' | 'orderType'>;

export const ResultPerPatient: React.FC = () => {
  const [patients, setPatients] = useState<PatientsData>();
  const [totalPatients, setTotalPatients] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const handlePatients = useCallback(async (listData: ListProps) => {
    const { period } = listData || {};

    try {
      setIsLoading(true);
      const { patients: loadedPatients, total } =
        (await listPatientsService({
          ...listData,
          startDate: period?.startDate,
          endDate: period?.endDate,
        })) || {};

      setPatients(loadedPatients);
      setTotalPatients(total);
    } catch {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: FormProps) => {
      try {
        const schema = Yup.object().shape({
          name: Yup.string().required('Digite o nome do paciente'),
          period: Yup.object({
            startDate: Yup.date().optional(),
            endDate: Yup.date().max(new Date()).optional(),
          }).optional(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { period, name: patientName } = data || {};

        handlePatients({
          order: 'ASC',
          orderType: 'Alphabetical',
          page: 1,
          take: 5,
          patientName,
          period,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);

          if (errors['period.startDate'] || errors['period.endDate']) {
            errors.period =
              errors['period.startDate'] || errors['period.endDate'];
          }

          formRef.current?.setErrors(errors);
        }
      }
    },
    [handlePatients],
  );

  useEffect(() => {
    handlePatients({
      order: 'ASC',
      orderType: 'Alphabetical',
      page: 1,
      take: 5,
    });
  }, [handlePatients]);

  return (
    <ResultPerPatientContainer>
      <SearchFilterContainer>
        <span className="title">Filtrar resultado</span>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <InputContainer>
            <InputText
              name="name"
              label="Nome do paciente"
              className="nameInput"
            />
            <InputDateRange name="period" label="Período" />
          </InputContainer>
          <SearchButtonWrapper>
            <SearchButton type="submit">
              <SearchIcon />
              Buscar
            </SearchButton>
          </SearchButtonWrapper>
        </Form>
      </SearchFilterContainer>
      <ListInfoContainer>
        <LeftInfo>
          <Order>
            <ArrowUpDownIcon />
            <span className="orderSelectorLabel">Ordenar por:</span>
          </Order>
          <Select
            onChange={async (newValue) => {
              const { value } = (newValue as { value: OrderOptions }) || {};

              await handlePatients({
                ...value,
                page: 1,
                take: 5,
              });
            }}
            name="patients"
            placeholder="Selecionar"
            defaultValue={options[0]}
            isErrored={false}
            isFocused={false}
            options={options}
            classNamePrefix="react-select"
            aria-haspopup="true"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => (
                <BsChevronDown strokeWidth={1} size={10} />
              ),
            }}
          />
        </LeftInfo>
        <RightInfo>
          <span>
            Exibindo{' '}
            <span className="displayedResults">
              {!patients && '00'}
              {patients && patients?.length < 10
                ? `0${patients?.length}`
                : patients?.length}
            </span>{' '}
            resultados de
            <span className="totalResults">
              {' '}
              {totalPatients >= 10 ? totalPatients : `0${totalPatients}`}
            </span>
          </span>
        </RightInfo>
      </ListInfoContainer>
      <PatientResultTable patients={patients} isLoading={isLoading} />
    </ResultPerPatientContainer>
  );
};
