import React, { useState } from 'react';
import { useCurrentEffect } from 'lib/use-current-effect';
import { countPatientSheetGroupedByStatus } from 'services/patient';
import { CountPatientSheetGroupedByStatusResponseDTO } from 'dtos/CountPatientSheetGroupedByStatusResponseDTO';
import { DataSectionCard } from '../DataSectionCard';

export const DataProgressionCard = () => {
  const [data, setData] =
    useState<CountPatientSheetGroupedByStatusResponseDTO>();

  useCurrentEffect((isCurrent) => {
    countPatientSheetGroupedByStatus({}).then(
      (response) => isCurrent() && setData(response),
    );
  }, []);

  if (!data) return <div />;
  return (
    <DataSectionCard
      title="Progressão de Dados"
      columnText="Tipo"
      data={[
        {
          name: 'Dados Preenchidos',
          amount: data.received,
          percent: Math.round((data.received / data.total) * 100),
        },
        {
          name: 'Dados Sem Preencher',
          amount: data.notReceived,
          percent: Math.round((data.notReceived / data.total) * 100),
          isMoreBetter: false,
        },
      ]}
      hasNavigation={false}
    />
  );
};
