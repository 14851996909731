import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useModal } from 'react-brave-modal';
import { useHistory } from 'react-router-dom';
import { differenceInYears } from 'date-fns';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { HiOutlinePencil } from 'react-icons/hi';
import { IoMdPower, IoIosWarning } from 'react-icons/io';
import { MdRemoveRedEye } from 'react-icons/md';
import { RiBankLine } from 'react-icons/ri';

import { usePatient } from '../../../../hooks/patient';
import { useAuth } from '../../../../hooks/auth';
import { usePermission } from '../../../../hooks/permission';
import getRiskDegree from '../../../../utils/getRiskDegree';
import { getObservationReason } from '../../../../utils/getObservationReason';
import PatientOnAlertModal from '../../../../modals/PatientOnAlertModal';

import { GridCardProps } from '../../../../components/GridCard';
import ActionMenuButton from '../../../../components/ActionMenuButton';
import HealthConditions from '../../../../components/HealthConditions';
import Avatar from '../../../../assets/images/avatar.svg';
import EmergencyContactsModal from './modals/EmergencyContactsModal';

import {
  Container,
  CardContent,
  CardHeader,
  CurrentCustomerStatus,
  ProfilePicture,
  Options,
  Risk,
  NameAndRiskDegree,
  ContentTable,
  Row,
  SeeProfileLink,
  InstitutionDetails,
  BandStatus,
  BandRecordUsageButton,
  Grid,
} from './styles';
import PatientMeasurementNoticeStatusEnum from '../../../../enums/PatientMeasurementNoticeStatusEnum';
import BraceletStatusTypeEnum from '../../../../enums/BraceletStatusTypeEnum';
import getBandStatusFromPatient from '../../../../services/patient/GetBandStatusFromPatient';
import theme from '../../../../styles/theme';
import ManualCalibrationBloodPressure from './components/ManualCalibrationBloodPressure';

interface PersonalDataCardProps extends GridCardProps {
  isPageAttendanceSummary?: boolean;
}

const PersonalDataCard: React.FC<PersonalDataCardProps> = ({
  gridArea,
  isPageAttendanceSummary,
}) => {
  const { showModal } = useModal();
  const { hasPermission } = usePermission();
  const history = useHistory();
  const { patient } = usePatient();
  const { user } = useAuth();

  const [bandStatus, setBandStatus] = useState<BraceletStatusTypeEnum>();
  const [bandUsageRecords] = useState([]);

  useEffect(() => {
    if (patient && patient.currentBraceletStatusId && patient.id) {
      getBandStatusFromPatient(patient.id).then((status) =>
        setBandStatus(status),
      );
    }
  }, [patient]);

  const canShow = useMemo(
    () => ({
      createPatient: hasPermission('register_patient'),
      editPatient: hasPermission('edit_patient'),
      canAttend:
        (hasPermission('video_attendance') ||
          hasPermission('call_attendance') ||
          hasPermission('message_attendance')) &&
        user?.type === 'professional',
    }),
    [hasPermission, user],
  );

  const patientData = useMemo(() => {
    const reason =
      getObservationReason(
        patient?.user?.currentStatus?.reason,
      )?.toUpperCase() ?? '';

    const response = {
      value: getRiskDegree.risk(),
      color: getRiskDegree.color(),
    };

    if (patient && patient.currentRiskDegree) {
      response.value = getRiskDegree.risk(patient.currentRiskDegree.degree);
      response.color = getRiskDegree.color(patient.currentRiskDegree.degree);
    }

    const lastManualCalibrationBP = patient?.manualCalibrationsBP
      ? patient.manualCalibrationsBP.reduce((latest, current) => {
          return new Date(latest.created_at) > new Date(current.created_at)
            ? latest
            : current;
        }, patient.manualCalibrationsBP[0])
      : undefined;

    return {
      id: patient?.id,
      avatar: patient?.user?.avatar || Avatar,
      name: patient?.user?.name,
      riskDegree: {
        risk: response.value,
        color: response.color,
      },
      age: patient?.user?.birthday
        ? differenceInYears(new Date(), new Date(patient.user.birthday))
        : 0,
      gender:
        patient?.user &&
        (patient?.user.gender === 'F'
          ? 'Feminino'
          : patient?.user.gender === 'M'
          ? 'Masculino'
          : 'Não definido'),
      city: patient?.user?.address?.city ?? '-',
      uf: patient?.user?.address?.uf ?? '-',
      email: patient?.user?.email,
      phone: patient?.user?.phone,
      cpf: patient?.user?.cpf,
      imc:
        patient?.complementaryData?.weight && patient?.complementaryData?.height
          ? (
              patient?.complementaryData?.weight /
              patient?.complementaryData?.height ** 2
            ).toFixed(2)
          : '-',
      displayableIcd10: patient?.healthConditions?.find(
        (i) => i.isMainHealthCondition,
      )?.icd10,
      additionalHealthCondition:
        patient?.healthConditions
          ?.filter((i) => !i.isMainHealthCondition)
          .map((i) => i.icd10) ?? [],
      healthConditionCount: (patient?.healthConditions?.length || 0) - 1,
      status: patient?.user?.currentStatus?.status,
      reason,
      inAlert:
        patient?.currentMeasurementNotice?.status ===
        PatientMeasurementNoticeStatusEnum.WAITING_FOR_ATTENDANCE,
      institution: patient?.institution?.name
        ? {
            name: patient.institution.name,
          }
        : undefined,
      manualCalibrationBloodPressure: lastManualCalibrationBP,
    };
  }, [patient]);

  const actionsThreeDotsMenuButton = useMemo(
    () =>
      patientData?.status !== 'draft'
        ? [
            {
              name: 'Editar Perfil',
              action: `/patient/edit?id=${patientData.id}`,
              icon: (
                <HiOutlinePencil
                  size={15}
                  color={theme.colors.grayAlternative}
                />
              ),
              disabled: !canShow.editPatient,
            },
            {
              name: patientData?.status === 'active' ? 'Desativar' : 'Ativar',
              action: `/patient/edit?id=${patientData.id}&page=Status`,
              icon: (
                <IoMdPower size={15} color={theme.colors.grayAlternative} />
              ),
              disabled: !canShow.editPatient,
            },
          ]
        : [
            {
              name: 'Editar Perfil',
              action: `/patient/edit?id=${patientData.id}&status=unfinished`,
              icon: (
                <HiOutlinePencil
                  size={15}
                  color={theme.colors.grayAlternative}
                />
              ),
              disabled: !canShow.editPatient,
            },
          ],
    [canShow, patientData],
  );

  const handleShowEmergencyContactsModal = useCallback(() => {
    showModal({
      type: 'custom',
      data: <EmergencyContactsModal />,
    });
  }, [showModal]);

  const handleShowPatientOnAlertModal = useCallback(() => {
    showModal({
      type: 'custom',
      data: <PatientOnAlertModal />,
    });
  }, [showModal]);

  return (
    <Container gridArea={gridArea}>
      <Grid>
        <ProfilePicture gridArea="pictureProfile">
          <img
            src={patientData.avatar || Avatar}
            alt={`Foto de ${patientData.name}`}
          />

          {patient?.currentBraceletStatusId && bandStatus && (
            <BandStatus status={BraceletStatusTypeEnum.ACTIVE}>
              {bandStatus === BraceletStatusTypeEnum.ACTIVE && (
                <span>Pulseira ativa</span>
              )}
              <div />
            </BandStatus>
          )}
          {patient?.currentBraceletStatusId && (
            <BandRecordUsageButton
              disabled={!bandUsageRecords.length}
              type="button"
            >
              Ver histórico de uso
            </BandRecordUsageButton>
          )}
        </ProfilePicture>
        <CardHeader gridArea="heading">
          <NameAndRiskDegree>
            <h1>{patientData.name}</h1>

            <Risk riskDegreeColor={patientData.riskDegree.color}>
              <p>Grau de Risco</p>
              <span>{patientData.riskDegree.risk}</span>
            </Risk>
          </NameAndRiskDegree>

          {patientData.institution && (
            <InstitutionDetails>
              <RiBankLine size={20} />

              <span>{`Instituição Vinculada: ${patientData.institution.name}`}</span>
            </InstitutionDetails>
          )}
          <button type="button" onClick={handleShowEmergencyContactsModal}>
            Ver contatos de emergência
          </button>
        </CardHeader>
        <CardContent gridArea="table">
          <ContentTable>
            <Row>
              <strong>Idade: </strong>
              <span>{patientData.age} anos</span>
            </Row>
            <Row>
              <strong>Sexo: </strong>
              <span>{patientData.gender}</span>
            </Row>
            <Row>
              <strong>Cidade: </strong>
              <span>{patientData.city ?? '-'}</span>
            </Row>
            <Row>
              <strong>Estado: </strong>
              <span>{patientData.uf ?? '-'}</span>
            </Row>
          </ContentTable>
          <ContentTable>
            <Row>
              <strong>E-mail: </strong>
              <span>{patientData.email ?? '-'}</span>
            </Row>
            <Row>
              <strong>Telefone: </strong>
              <span>{patientData.phone}</span>
            </Row>
            <Row>
              <strong>CPF: </strong>
              <span>{patientData.cpf}</span>
            </Row>
            <Row>
              <strong>IMC: </strong>
              <span>{patientData.imc}</span>
            </Row>
          </ContentTable>
          <ContentTable>
            {!!patientData.displayableIcd10 && (
              <HealthConditions
                displayableIcd10={patientData.displayableIcd10}
                healthConditionsCount={patientData.healthConditionCount}
                healthConditions={patientData.additionalHealthCondition}
              />
            )}
            {isPageAttendanceSummary && (
              <SeeProfileLink to={`/patient/profile?id=${patientData?.id}`}>
                <MdRemoveRedEye size={25} />
                <span>Prontuário</span>
              </SeeProfileLink>
            )}
          </ContentTable>
        </CardContent>
        <Options gridArea="options">
          <ActionMenuButton
            previousLocation={history.location.pathname}
            alignBox="center"
            list={actionsThreeDotsMenuButton}
          >
            <BsThreeDotsVertical size={18} color="#C4C4C4" />
          </ActionMenuButton>
        </Options>
      </Grid>

      {patientData && patientData.manualCalibrationBloodPressure && (
        <ManualCalibrationBloodPressure
          systolic={patientData.manualCalibrationBloodPressure.systolic}
          diastolic={patientData.manualCalibrationBloodPressure.diastolic}
        />
      )}

      {patientData.status === 'draft' ? (
        <CurrentCustomerStatus type="info">
          <IoIosWarning />

          <p>
            <b>Atenção: </b>
            Para realizar atendimentos é necessário completar o cadastro do
            paciente.
          </p>

          <button
            type="button"
            onClick={() =>
              history.push(
                `/patient/register/complete?id=${patient?.id}&status=unfinished`,
              )
            }
            disabled={!canShow.createPatient}
          >
            Completar Cadastro
          </button>
        </CurrentCustomerStatus>
      ) : patientData.status === 'inactive' ? (
        <CurrentCustomerStatus type="info">
          <p>
            <b>Paciente inativo </b>
            por motivo de
            <b> {patientData.reason}</b>
          </p>

          <button
            type="button"
            onClick={() =>
              history.push(`/patient/edit?id=${patientData.id}&page=Status`)
            }
            disabled={!canShow.editPatient}
          >
            Alterar Status
          </button>
        </CurrentCustomerStatus>
      ) : patientData.inAlert ? (
        <CurrentCustomerStatus type="danger">
          <IoIosWarning />

          <p>
            <b>Atenção: </b>O paciente encontra-se em alerta.
          </p>

          <button
            type="button"
            onClick={handleShowPatientOnAlertModal}
            disabled={!canShow.canAttend}
          >
            Alterar Status
          </button>
        </CurrentCustomerStatus>
      ) : (
        <></>
      )}
    </Container>
  );
};
export default PersonalDataCard;
