import styled from 'styled-components';

export const InsightsContainer = styled.div`
  grid-area: insights;

  padding: 33px 17px 44px 31px;

  background: #fff;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  gap: 17px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

  img {
    color: #53e2a6;
    width: 17px;
    height: 17px;
  }

  .titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  .titleContainer img {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
  }

  .insightTitle {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    color: #53e2a6;
  }

  .insightText {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.78px;
  }
  .insightHighlightedText {
    font-weight: 700;
  }
`;
