export const allOptionsDefaultValue = 'Todos';

export const genderOptions = [
  {
    id: 0,
    label: 'Todos',
    isChecked: true,
  },
  {
    id: 1,
    label: 'Masculino',
    isChecked: true,
  },
  {
    id: 2,
    label: 'Feminino',
    isChecked: true,
  },
  {
    id: 3,
    label: 'Outros',
    isChecked: true,
  },
];

export const healthConditionOptions = [
  {
    id: 0,
    label: 'Todos',
    isChecked: true,
  },
  {
    id: 1,
    label: 'Hipertensão',
    isChecked: true,
  },
  {
    id: 2,
    label: 'Diabetes',
    isChecked: true,
  },
];

export const timePeriodOptions = [
  {
    id: 0,
    label: 'Mais recentes',
    isChecked: true,
  },
  {
    id: 1,
    label: 'Mais antigas',
    isChecked: false,
  },
  {
    id: 2,
    label: 'Últimos 15 dias',
    isChecked: false,
  },
  {
    id: 3,
    label: 'Últimos 30 dias',
    isChecked: false,
  },
  {
    id: 4,
    label: 'Últimos 90 dias',
    isChecked: false,
  },
];

export const questionOptions = [
  {
    id: 0,
    label: 'Todos',
    isChecked: true,
  },
  {
    id: 1,
    label: '1',
    isChecked: true,
  },
  {
    id: 2,
    label: '2',
    isChecked: true,
  },
  {
    id: 3,
    label: '3',
    isChecked: true,
  },
  {
    id: 4,
    label: '4',
    isChecked: true,
  },
  {
    id: 5,
    label: '5',
    isChecked: true,
  },
  {
    id: 6,
    label: '6',
    isChecked: true,
  },
  {
    id: 7,
    label: '7',
    isChecked: true,
  },
  {
    id: 8,
    label: '8',
    isChecked: true,
  },
  {
    id: 9,
    label: '9',
    isChecked: true,
  },
  {
    id: 10,
    label: '10',
    isChecked: true,
  },
];

export const categoryOptions = [
  {
    id: 0,
    label: 'Todos',
    isChecked: true,
  },
  {
    id: 1,
    label: 'Iniciando a trilha',
    isChecked: true,
  },
  {
    id: 2,
    label: 'Iniciando a trilha (Mulheres)',
    isChecked: true,
  },
  {
    id: 3,
    label: 'Iniciando a trilha (Homens 40+)',
    isChecked: true,
  },
  {
    id: 4,
    label: 'Saúde Mental',
    isChecked: true,
  },
  {
    id: 5,
    label: 'Bem Estar',
    isChecked: true,
  },
  {
    id: 6,
    label: 'Atividades Físicas',
    isChecked: true,
  },
];
