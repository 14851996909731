import styled from 'styled-components';
import { ReactComponent as Swap } from 'assets/images/swap-outline.svg';

import Button from 'components/Button';

export const AdvancedAnalysisButton = styled(Button)`
  margin-top: 21px;
  margin-bottom: 36px;
`;

export const SwapIcon = styled(Swap)`
  margin-right: 8px;
`;

export const DataSection = styled.section`
  display: grid;
  justify-content: center;

  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  grid-auto-rows: 670px;
  gap: 24px;

  margin-top: 24px;

  > :first-child {
    display: flex;
    flex-direction: column;

    > :first-child {
      margin-bottom: 24px;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    display: flex;
    flex-direction: column;

    > div {
      max-height: 670px;
    }
  }
`;
