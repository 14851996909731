/* eslint-disable @typescript-eslint/no-explicit-any */
export function abbreviateNumber(
  value: number,
  options: Omit<Intl.NumberFormatOptions, 'notation' | ''> = {},
) {
  const { maximumFractionDigits = 1, ...rest } = options;

  return Intl.NumberFormat('pt-BR', {
    notation: 'compact',
    maximumFractionDigits,
    ...rest,
  } as any).format(value);
}
