import React from 'react';
import { PieChart } from 'components/PieChart';
import {
  InnerChartContainer,
  ParamIndicator,
  SmartBandContainer,
  TableWrapper,
} from './styles';

const smartBandData = [
  {
    short: 'BPM',
    name: 'Batimentos',
    amount: 479,
    color: '#6E46C3',
  },
  {
    short: 'P.A',
    name: 'Pressão Arterial',
    amount: 328,
    color: '#2C7BE5',
  },
  {
    short: 'OX',
    name: 'Oxigenação',
    amount: 835,
    color: '#F1BA2C',
  },
];

export const SmartBand: React.FC = () => {
  const formatPieChartData = (chartDataItem: typeof smartBandData) => {
    return chartDataItem.map((item): [string, number] => [
      item.name,
      item.amount,
    ]);
  };

  const totalPatients = smartBandData.reduce(
    (previousValue, currentElement) => {
      return previousValue + currentElement.amount;
    },
    0,
  );

  return (
    <SmartBandContainer>
      <div className="headerWrapper">
        <span className="title">Informações da Smartband</span>
        <span className="smallDescription">Dados dos pacientes</span>
      </div>

      <div className="chartInfoWrapper">
        <PieChart
          chartData={formatPieChartData(smartBandData)}
          colors={smartBandData.map((data) => data.color)}
          height={206}
          width={206}
        >
          <InnerChartContainer>
            <span>Total de Pacientes</span>
            <strong>{totalPatients}</strong>
          </InnerChartContainer>
        </PieChart>
        <TableWrapper>
          <table>
            <thead>
              <tr>
                <th className="paramHeader">Parâmetros</th>
                <th>Fora dos padrões</th>
                <th>% Fora dos padrões</th>
              </tr>
            </thead>

            <tbody>
              {smartBandData.map(({ name, short, amount, color }) => (
                <tr key={name}>
                  <td className="paramInfoColumn">
                    <ParamIndicator backgroundColor={color} />

                    <div className="paramNameWrapper">
                      <strong>{short}</strong>
                      <span>{name}</span>
                    </div>
                  </td>
                  <td>
                    <span className="amountText">{amount}</span>
                  </td>
                  <td>
                    <span className="percentageText">
                      {((amount / totalPatients) * 100).toFixed(1)}%
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableWrapper>
      </div>
    </SmartBandContainer>
  );
};
