import { api } from 'services/api';

interface Return {
  name: string;
  patientCount: number;
}

export const getSheetById = async (sheetId?: string): Promise<Return> => {
  const { data } = await api.get<Return>(`sheets/${sheetId}`);

  return data;
};
