import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    padding: 0 16px;
  }

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3;
  padding: 2;

  > p {
    font-family: Raleway;
    margin: 0;
    text-align: center;
    line-height: 18.78px;
    color: ${({ theme }) => theme.colors.graySecondary};
    font-size: 16px;
  }

  p:first-of-type {
    font-weight: 500;
    padding: 0 8px;
  }

  p:last-of-type {
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    p {
      font-size: 14px;
      line-height: 15px;
    }
  }

  @media (max-width: 768px) {
    p {
      font-size: 14px;
      line-height: 15px;
    }
  }
`;
