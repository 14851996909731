import React, { createContext, ReactNode, useContext, useState } from 'react';
import useQuery from 'hooks/query';
import { useCurrentEffect } from 'lib/use-current-effect';
import { getIcd10ById } from 'services/icd10';
import { getSheetById, GetSheetCount } from 'services/sheet';

interface HealthSearchParams {
  icd10: {
    name: string;
    patientCount: number;
  };
  sheet: {
    name: string;
    patientCount: number;
  };
}

const Context = createContext<HealthSearchParams | null>(null);

interface WellFareContext {
  children: ReactNode;
}
export const WellFareContext = ({ children }: WellFareContext) => {
  const [value, setValue] = useState<HealthSearchParams | null>(null);

  const query = useQuery();
  const icd10Id = query.get('icd10');
  const sheetId = query.get('sheet');

  useCurrentEffect(
    (isCurrent) => {
      const allSheets = GetSheetCount();

      if (!sheetId && icd10Id) {
        const getIcd10 = getIcd10ById(icd10Id);
        Promise.all([getIcd10, allSheets]).then(([icd10, sheet]) =>
          setValue({
            icd10,
            sheet: { name: 'Todas as fichas', patientCount: sheet.length },
          }),
        );
      }

      if (!icd10Id && sheetId) {
        const getSheet = getSheetById(sheetId);
        Promise.all([getSheet]).then(([sheet]) =>
          setValue({
            icd10: { name: 'Todas as condições', patientCount: 0 },
            sheet,
          }),
        );
      }

      if (icd10Id && sheetId) {
        const getIcd10 = getIcd10ById(icd10Id);
        const getSheet = getSheetById(sheetId);
        Promise.all([getIcd10, getSheet]).then(
          ([icd10, sheet]) => isCurrent() && setValue({ icd10, sheet }),
        );
      } else {
        setValue({
          icd10: { name: 'Todas as condições', patientCount: 0 },
          sheet: { name: 'Todas as fichas', patientCount: 0 },
        });
      }
    },

    [icd10Id, sheetId],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useWellFareContext = () => {
  return useContext(Context);
};
