import { CountPatientSheetGroupedByStatusResponseDTO } from 'dtos/CountPatientSheetGroupedByStatusResponseDTO';
import { CountPatientSheetGroupedByStatusRequestDTO } from 'dtos/CountPatientSheetGroupedByStatusRequestDTO';
import { api } from '../api';

export const countPatientSheetGroupedByStatus = async ({
  icd10,
  sheet,
  end,
  start,
}: CountPatientSheetGroupedByStatusRequestDTO): Promise<CountPatientSheetGroupedByStatusResponseDTO> => {
  const { data } = await api.get('/sheets/patients/countGroupedByStatus', {
    params: {
      icd10,
      sheet,
      end,
      start,
    },
  });

  return data;
};
