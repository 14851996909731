import React, { useState } from 'react';
import { countPatientSheetGroupedByStatus } from 'services/patient';
import useQuery from 'hooks/query';
import { CountPatientSheetGroupedByStatusResponseDTO } from 'dtos/CountPatientSheetGroupedByStatusResponseDTO';
import { useCurrentEffect } from 'lib/use-current-effect';
import { useWellFareContext } from '../../WellFareContext';
import { PatientDataContainer, PatientInfoWrapper } from './styles';

export const PatientData: React.FC = () => {
  const contextValue = useWellFareContext();
  const [data, setData] =
    useState<CountPatientSheetGroupedByStatusResponseDTO>();
  const query = useQuery();

  useCurrentEffect(
    (isCurrent) => {
      countPatientSheetGroupedByStatus({
        icd10: query.get('icd10'),
        sheet: query.get('sheet'),
      }).then((response) => isCurrent() && setData(response));
    },
    [query],
  );
  if (!contextValue || !data) return <div />;
  return (
    <PatientDataContainer>
      <div className="headerWrapper">
        <span className="patientDataTitle">Dados</span>
        <span className="patientDataText">
          Total de {data.received} pacientes
        </span>
      </div>
      <PatientInfoWrapper>
        <div className="infoLine">
          <span className="infoNameText">{contextValue.icd10.name}</span>
          {query.get('icd10') && (
            <span className="infoDescriptionText">
              {contextValue.icd10.patientCount} pacientes
            </span>
          )}
        </div>
        <div className="infoLine">
          <span className="infoNameText">{contextValue.sheet.name}</span>
          {query.get('sheet') && (
            <span className="infoDescriptionText">
              {contextValue.sheet.patientCount} preencheram os dados
            </span>
          )}
        </div>
      </PatientInfoWrapper>
    </PatientDataContainer>
  );
};
