import styled from 'styled-components';

interface ParamIndicatorProps {
  backgroundColor: string;
}

export const SmartBandContainer = styled.div`
  padding: 23px 49px 76px 39px;
  background: #ffffff;
  border-radius: 6px;

  grid-area: smartBand;

  box-shadow: 0px 4px 10px 0px rgba(6, 92, 119, 0.05);

  .headerWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .headerWrapper .title {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #4f4f4f;
  }

  .headerWrapper .smallDescription {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0em;
    color: #707683;
  }

  .chartInfoWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 45px;
  }
`;

export const InnerChartContainer = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0em;
    color: #4f4f4f;
  }

  strong {
    font-family: Raleway;
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 0em;
    color: #4f4f4f;
  }
`;

export const TableWrapper = styled.div`
  table {
    border: none;
    width: 465px;
  }

  tr:not(:last-child) {
    td,
    th {
      border-bottom: 1px solid #ebeff2;
    }
  }

  th {
    padding-bottom: 12px;
  }

  td {
    padding: 12px 0;
  }

  .paramHeader {
    padding-left: 34px;
  }

  .paramInfoColumn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }

  .paramNameWrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .paramNameWrapper strong {
    font-family: Raleway;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    color: #4f4f4f;
  }

  .paramNameWrapper span {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
    color: #4f4f4f;
  }

  td span {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
    color: #4f4f4f;
  }

  th {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
    color: #4f4f4f;
  }
`;

export const ParamIndicator = styled.div<ParamIndicatorProps>`
  background: ${({ backgroundColor }) => backgroundColor};
  width: 10px;
  height: 10px;
  border-radius: 100%;
`;
