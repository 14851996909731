import styled from 'styled-components';

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(6, 92, 119, 0.05);
  padding: 30px 25px 40px;

  h1 {
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.graySecondary};
  }
`;
