import styled from 'styled-components';

export const AgeChartTotalArea = styled.div`
  width: 40%;
  text-align: right;
  margin-right: 20px;
  margin-top: 20px;

  > p {
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.graySecondary};
  }

  > strong {
    font-size: 18px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.graySecondary};
  }
`;

export const AgeChartTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 14px;
`;

export const ChartContainer = styled.div``;

export const DataSectionCardTableDataName = styled.td`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.graySecondary};

  > span {
    font-weight: 500;
  }
`;

export const DataChartCardTd = styled(DataSectionCardTableDataName)`
  display: flex;
  align-items: center;
`;

export const DataChartCardRightColumn = styled(DataSectionCardTableDataName)`
  text-align: right;
`;
