import { GetPatientCountGroupedByAgeRequestDTO } from 'dtos/GetPatientCountGroupedByAgeRequestDTO';
import { GetPatientCountGroupedByAgeResponseDTO } from 'dtos/GetPatientCountGroupedByAgeResponseDTO';
import { api } from '../api';

export const getPatientCountGroupedByAge = async ({
  icd10,
  sheet,
  end,
  start,
}: GetPatientCountGroupedByAgeRequestDTO): Promise<GetPatientCountGroupedByAgeResponseDTO> => {
  const { data } = await api.get('/patients/countGroupedByAge', {
    params: {
      icd10,
      sheet,
      end,
      start,
    },
  });

  return data;
};
