import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

const useQuery = () => {
  const { search } = useLocation();
  const memo = useMemo(() => new URLSearchParams(search), [search]);
  return memo;
};

export default useQuery;
