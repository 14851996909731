import React from 'react';
import { IoMdEye } from 'react-icons/io';
import { ListPatientResponseDTO } from 'services/patient/listPatientsService';
import personAvatar from '../../../../../../assets/images/avatar.svg';
import {
  EyeButton,
  EyeButtonContainer,
  GridHeaderItem,
  PatientTable,
  PatientTableContainer,
  TableContent,
  TableHeader,
} from './styles';
import Spinner from '../../../../../../components/Spinner';

interface PatientResultTableProps {
  patients?: ListPatientResponseDTO['patients'];
  isLoading: boolean;
}

interface GridData {
  value: string | Date;
}

const grids = [
  {
    handleGridDisplay: ({ value: name }: GridData) => (
      <div key={1} className="gridContentItem">
        <div className="avatarContainer">
          <img src={personAvatar} alt="" />
        </div>
        <span className="patientName">{name}</span>
      </div>
    ),
  },
  {
    handleGridDisplay: ({ value: date }: GridData) => (
      <div key={2} className="gridContentItem">
        <span className="gridText">{new Date(date).toLocaleDateString()}</span>
      </div>
    ),
  },
  {
    handleGridDisplay: ({ value: healthStatus }: GridData) => (
      <div
        key={3}
        data-state={healthStatus === '-' ? 'true' : 'false'}
        className="healthConditionGrid"
      >
        <span className="gridText">{healthStatus}</span>
      </div>
    ),
  },
  {
    handleGridDisplay: () => (
      <div key={4} className="gridEyeItem">
        <EyeButtonContainer>
          <EyeButton type="button" onClick={() => undefined}>
            <IoMdEye />
          </EyeButton>
          <span className="eyeButtonText">VISUALIZAR</span>
        </EyeButtonContainer>
      </div>
    ),
  },
];

export const PatientResultTable: React.FC<PatientResultTableProps> = ({
  patients,
  isLoading,
}) => {
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <PatientTableContainer>
      <PatientTable>
        <TableHeader>
          <GridHeaderItem>
            <span>Paciente</span>
          </GridHeaderItem>
          <GridHeaderItem>
            <span>Data</span>
          </GridHeaderItem>
          <GridHeaderItem>
            <span>Condição de Saúde</span>
          </GridHeaderItem>
        </TableHeader>
        <TableContent>
          {patients?.map((patient) =>
            grids.map(({ handleGridDisplay }, index) => {
              const keys = Object.keys(patient).filter(
                (key) => key !== 'patientId',
              );
              const foundKey = keys.find(
                (key) => key === keys[index],
              ) as keyof ListPatientResponseDTO['patients'][number];

              if (foundKey && patient[foundKey]) {
                return handleGridDisplay({ value: patient[foundKey] });
              }

              return handleGridDisplay({ value: '-' });
            }),
          )}
        </TableContent>
      </PatientTable>
    </PatientTableContainer>
  );
};
