import styled from 'styled-components';
import { colors } from '../../../../../styles/theme/colors';

interface BarChartProps {
  value: number;
}

export const QuestionCardContainer = styled.div`
  background: #ffffff;
  border-radius: 6px;
  padding: 30px;
  width: 100%;

  & + div {
    margin-top: 24px;
  }

  .questionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .questionContainer > span {
    font-family: 'Raleway';
    color: #4f4f4f;
    font-weight: 700;
    font-size: 14px;
  }

  .resultContainer {
    border-radius: 100px;
    border: none;
    background: ${colors.gray6};
    padding: 0 13px;
    height: 29px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .resultContainer > span {
    font-family: Raleway;
    font-weight: 600;
    font-size: 12px;
    color: #4f4f4f;
    letter-spacing: 0.12px;
  }

  .optionsContainer {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-left: 30px;

    gap: 32px;
  }

  .optionsTextContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  .optionsTextContainer > span {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    color: #4f4f4f;
    white-space: nowrap;

    & + span {
      margin-top: 37px;
    }
  }

  .percentageValueText {
    font-family: Raleway;
    font-weight: 700;
    font-size: 16px;
    color: #4f4f4f;
  }

  .barChartContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }

  .barItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    width: 100%;

    & + .barItem {
      margin-top: 32px;
    }
  }
`;

export const BarChart = styled.div<BarChartProps>`
  height: 21px;
  width: ${({ value }) => value}%;
  border-radius: 0px 50px 50px 0px;
  background-image: linear-gradient(to right, #2d9cdb, #30e3e5);
`;
