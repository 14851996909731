/* eslint-disable import/no-duplicates */
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useCurrentEffect } from '../../../../../../lib/use-current-effect';

import { usePatient } from '../../../../../../hooks/patient';
import { getMeasurementsByDay } from '../../../../../../services/metrics';

import MeasurementsArea from '../../MeasurementsArea';
import Img from '../../../../../../assets/images/sono.svg';

import { ChildrenProps, ChildrenRefProps } from '../..';
import { MetricTypeEnum } from '../../../../../../enums/MetricTypeEnum';

const SleepChart: React.ForwardRefRenderFunction<
  ChildrenRefProps,
  ChildrenProps
> = ({ dateFilter }, ref) => {
  const { patient } = usePatient();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<string | number>();

  const handleChange = useCallback(
    async (patientId?: string, isCurrent = () => true) => {
      setIsLoading(true);
      if (!patientId) {
        setIsLoading(false);
        return;
      }
      const response = await getMeasurementsByDay({
        patientId,
        metricType: MetricTypeEnum.SLEEP,
        dateFilter,
      });

      if (response.length > 0) {
        const result = response.find(
          ({ sleepTotalTimeParsed, mensuramentDate }) => [
            format(new Date(mensuramentDate), 'HH:mm', {
              locale: ptBR,
            }),
            sleepTotalTimeParsed,
          ],
        );

        const value = result?.sleepTotalTimeParsed.toString();

        if (isCurrent()) {
          setData(value);
        }
      } else if (isCurrent()) {
        setData(undefined);
      }

      setIsLoading(false);
    },
    [dateFilter],
  );

  useCurrentEffect(
    (isCurrent) => {
      (() => {
        if (patient?.id) {
          handleChange(patient.id, isCurrent);
        } else {
          setIsLoading(false);
        }
      })();
    },
    [dateFilter, handleChange, patient],
  );

  useImperativeHandle(ref, () => ({
    reload: () => handleChange(patient?.id),
  }));

  return (
    <MeasurementsArea
      data={{
        isLoading,
        styleData: 'info',
        dataDetails: data,
      }}
    >
      <img src={Img} alt="icone de sono" />

      <div>
        <span>Hoje o paciente dormiu</span>

        <h1>{data}</h1>
      </div>
    </MeasurementsArea>
  );
};

export default forwardRef(SleepChart);
