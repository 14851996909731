import styled, { css } from 'styled-components';

interface ContainerProps {
  withMaxWidth: boolean;
}

interface DatePickerComponentProps {
  invisible: boolean;
  invalidDate?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;

  display: flex;

  align-items: center;

  min-width: 160px;
  ${({ withMaxWidth }) =>
    withMaxWidth &&
    css`
      max-width: 245px;
    `}

  height: 43px;

  button {
    &:disabled {
      opacity: 0.6;
    }
  }
`;

export const DatePickerComponent = styled.div<DatePickerComponentProps>`
  z-index: 1;
  position: absolute;
  right: 0;
  top: 20px;
  background: #f9f8fc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    top: 40px;
    margin-right: -22.5px;
  }

  .rdrDateRangeWrapper {
    display: flex;
    flex-direction: column;
  }

  .titleCalendar {
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #707683;
    margin: 0 0 10px 20px;

    ${({ invalidDate }) =>
      invalidDate &&
      css`
        color: red;
      `}
  }

  .rdrMonthAndYearPickers {
    display: flex;
    justify-content: space-between;
    margin: 10px 5px;

    span > select {
      text-transform: capitalize;
      font-weight: bold;
    }
  }

  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrMonthsVertical {
    order: 1;
  }

  .rdrWeekDay {
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 0.01em;
    color: #000000;
  }

  .rdrDay > span {
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.01em;
    color: #5f5c6b;
  }

  .rdrDayPassive {
    color: #c6c5ce;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    background: #2d9cdb;
    font-weight: bold;
    color: #ffffff;

    ${({ invalidDate }) =>
      invalidDate &&
      css`
        background: red;
      `}
  }

  .rdrInRange {
    background: rgba(45, 156, 219, 0.5);

    ${({ invalidDate }) =>
      invalidDate &&
      css`
        background: rgba(255, 0, 0, 0.5);
      `}
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
    color: #5f5c6b;
  }

  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
    font-weight: bold;
  }

  ${({ invisible }) =>
    invisible &&
    css`
      visibility: hidden;
    `}
`;

export const ButtonClose = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 6px;

  padding: 2px;

  background-color: #bdbdbd;
  border: transparent;
  border-radius: 50%;
`;

export const ButtonDatePicker = styled.button`
  background: transparent;
  border: 0;

  text-align: left;

  display: flex;

  align-items: center;
  justify-content: space-between;

  width: 100%;

  flex-wrap: nowrap;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #4f4f4f;

  > div {
    display: flex;

    margin-left: 9px;
    background: #f0f0f0;
    border-radius: 50%;
    padding: 7px 8px;

    > img {
      width: 13px;
    }
  }
`;
