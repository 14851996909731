import styled from 'styled-components';

interface BarProps {
  fillColor: string;
  width: number;
}

export const HorizontalBarChartContainer = styled.div`
  flex: 1;

  > :not(:first-child) {
    margin-top: 4px;
  }
`;

export const HorizontalBarChartBar = styled.div<BarProps>`
  background-color: ${({ fillColor }) => fillColor};
  height: 13px;
  width: ${({ width }) => `${width}%`};
  border-radius: 0 8px 8px 0;
`;
