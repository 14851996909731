import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Public/Login';
import Welcome from '../pages/Welcome';
import Error404 from '../pages/Public/Error404';
import VideoRoom from '../pages/Public/VideoRoom';
import PatientProfile from '../pages/Patient/Profile';
import UserProfile from '../pages/User/Profile';
import ResetPassword from '../pages/User/ResetPassword';
import ForgotPassword from '../pages/User/ForgotPassword';
import UserEdit from '../pages/User/Edit';
import UserList from '../pages/User/List';
import ProfileList from '../pages/Profile/List';
import ProfileUserItem from '../pages/Profile/Item/UserItemProfile';
import PatientRegister from '../pages/Patient/Register';
import PatientEdit from '../pages/Patient/Edit';
import DashboardNotification from '../pages/Dashboard/Notification';
import ListPatients from '../pages/Patient/List';
import DashboardProfessional from '../pages/Professional/Dashboard';
import Attendance from '../pages/Patient/Attendance';
import AppointmentsList from '../pages/Appointments/List';
import SheetAnswersList from '../pages/Sheet/AnswersList';
import SheetPendenciesList from '../pages/Sheet/PendenciesList';
// import SignatureList from '../pages/Signature/List';
// import SignatureProfile from '../pages/Signature/Profile';
import AttendanceReports from '../pages/Reports/Attendance/List';
import PatientsReports from '../pages/Reports/Patients';
import InstitutionList from '../pages/Institution/List';
import InstitutionEdit from '../pages/Institution/Edit';
import NoticesDashboard from '../pages/Notices/Dashboard';
import { WellFare } from '../pages/Administration/WellFare';
import { DataList } from '../pages/Data/List';

const Routes: React.FC = () => (
  <Switch>
    <Redirect from="/" to="/login" exact />
    <Route path="/login" exact component={Login} />
    <Route path="/welcome" exact component={Welcome} />
    <Route path="/password/reset" exact component={ResetPassword} />
    <Route path="/password/forgot" exact component={ForgotPassword} />
    <Route path="/video" exact component={VideoRoom} />
    {/* <Route path="/dashboard" exact component={DashboardMain} /> */}
    <Route
      path="/dashboard/profile"
      exact
      component={DashboardProfessional}
      isPrivate
    />
    <Route
      path="/patient/profile"
      exact
      component={PatientProfile}
      isPrivate
      permission="patient_profile"
    />
    <Route
      path="/patient/register"
      exact
      component={PatientRegister}
      isPrivate
      permission="register_patient"
    />
    <Route
      path="/patient/list"
      exact
      component={ListPatients}
      isPrivate
      permission="list_of_patients"
    />
    <Route path="/patient/attendance" exact component={Attendance} isPrivate />
    <Route
      path="/patient/register/complete"
      exact
      component={PatientEdit}
      isPrivate
      permission="register_patient"
    />
    <Route
      path="/patient/edit"
      exact
      component={PatientEdit}
      isPrivate
      permission="edit_patient"
    />
    <Route
      path="/user/profile"
      exact
      component={UserProfile}
      isPrivate
      permission="user_profile"
    />
    <Route
      path="/user/register"
      exact
      component={UserEdit}
      isPrivate
      permission="register_user"
    />
    <Route path="/user/edit" exact component={UserEdit} isPrivate />
    <Route
      path="/user/list"
      exact
      component={UserList}
      isPrivate
      permission="list_of_users"
    />
    <Route
      path="/profile/list"
      exact
      component={ProfileList}
      isPrivate
      permission="list_profiles"
    />
    <Route
      path="/profile/:id"
      exact
      component={ProfileUserItem}
      isPrivate
      permission="list_profile_users"
    />
    <Route
      path="/appointments/list"
      exact
      component={AppointmentsList}
      isPrivate
      permission="list_of_appointments"
    />
    <Route
      path="/data/list"
      exact
      component={DataList}
      isPrivate
      permission="list_health_sheets"
    />
    <Route
      path="/sheet/answers"
      exact
      component={SheetAnswersList}
      isPrivate
      permission="list_health_sheets"
    />
    <Route
      path="/sheet/pendencies"
      exact
      component={SheetPendenciesList}
      isPrivate
      permission="list_health_sheets"
    />

    <Route
      path="/institution/list"
      exact
      component={InstitutionList}
      isPrivate
      permission="list_institutions"
    />

    <Route
      path="/institution/register"
      exact
      component={InstitutionEdit}
      isPrivate
    />
    {/*     <Route
      path="/signature/list"
      exact
      component={SignatureList}
      isPrivate
      permission="list_signatures"
    /> */}
    {/*     <Route
      path="/signature/profile"
      exact
      component={SignatureProfile}
      isPrivate
      permission="view_signature"
    /> */}
    <Route
      path="/reports/attendance/list"
      exact
      component={AttendanceReports}
      isPrivate
      permission="attendance_report"
    />
    <Route
      path="/reports/patients"
      exact
      component={PatientsReports}
      isPrivate
      permission="patient_report"
    />
    <Route
      path="/dashboard/notifications"
      exact
      component={DashboardNotification}
      isPrivate
    />
    <Route
      path="/dashboard/notices"
      exact
      component={NoticesDashboard}
      isPrivate
      permission="view_notices_dashboard"
    />
    {/* <Route
      path="/administration/sheetFormIo"
      exact
      component={CreateSheetFormIo}
    /> */}
    {/* <Route path="/administration/sheet" exact component={SheetInit} /> */}
    <Route
      path="/administration/well_fare"
      exact
      component={WellFare}
      permission="list_health_sheets"
      isPrivate
    />
    <Route path="*" component={Error404} />
  </Switch>
);

export default Routes;
