import styled from 'styled-components';

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
`;

export const QuestionsCardContainer = styled.div`
  margin-top: 32px;
  box-shadow: 0px 4px 10px 0px rgba(6, 92, 119, 0.05);
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 35px;
  width: 100%;
`;

export const DownloadContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  position: relative;

  span {
    font-weight: 600;
    font-size: 12px;
    color: #4f4f4f;
  }

  svg {
    color: #4f4f4f;
  }
`;

export const DownloadModal = styled.div`
  border-radius: 6px;
  background: #ffffff;
  min-width: 200px;
  position: absolute;

  top: 30px;
  right: 0;
`;

export const DownloadItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  padding: 12px;

  svg {
    height: 16px;
    width: 16px;
    color: #4f4f4f;
  }

  span {
    font-family: Raleway;
    font-weight: 600;
    font-size: 12px;
    color: #4f4f4f;
  }

  & + div {
    border-top: 1px solid #ebeff2;
  }

  &:hover {
    background: #daedf7;

    span {
      color: #2d9cdb;
    }

    svg {
      color: #2d9cdb;
    }

    div {
      border-top: transparent;
    }
  }
`;
