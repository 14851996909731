import React, { useEffect, useRef, useState } from 'react';
import { BsFunnel, BsChevronCompactDown, BsCalendar } from 'react-icons/bs';

import { MdClose, MdSearch } from 'react-icons/md';
import { DateRange, RangeKeyDict } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import { differenceInDays } from 'date-fns';
import {
  CalendarContainer,
  ClearButtonContainer,
  FilterButton,
  FilterButtonsContainer,
  FilterOptionsContainer,
  IconButton,
  QuestionsFilterContainer,
} from './styles';
import { BoxProps, FilterButtonsData } from '..';

interface DateValueProps {
  startDate?: Date;
  endDate?: Date;
}

interface QuestionsFilterProps {
  filterButtons: FilterButtonsData;
  filterDate: DateValueProps;
  onFilterButtonClick: (id: number) => void;
  onCheckBoxClick: (data: BoxProps) => void;
  onRadioBoxClick: (data: BoxProps) => void;
  clearFilterButton: (data: number) => void;
  onChangeDate: (rangesByKey: RangeKeyDict) => void;
  onCalendarClick: () => void;
}

export const QuestionsFilter: React.FC<QuestionsFilterProps> = ({
  filterButtons,
  filterDate,
  onFilterButtonClick,
  onCheckBoxClick,
  onRadioBoxClick,
  clearFilterButton,
  onCalendarClick,
  onChangeDate,
}) => {
  const [searchText, setSearchText] = useState('');

  const [shouldOpenCalendar, setShouldOpenCalendar] = useState(false);

  const searchRef = useRef<HTMLInputElement>(null);

  const maxDate = new Date();
  const minDate = new Date(2019, 0, 1);

  useEffect(() => {
    const length = searchRef?.current?.value?.length;

    const delayDebounce = setTimeout(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (length && length > 0) {
          setSearchText(e.target.value);
        }
      },
      500,
    );

    return () => clearTimeout(delayDebounce);
  }, [searchRef]);

  const onChange = (rangesByKey: RangeKeyDict) => {
    onChangeDate(rangesByKey);

    const { startDate, endDate } = rangesByKey.range1;

    const shouldCloseCalendar =
      endDate && startDate && differenceInDays(endDate, startDate) !== 0;

    if (shouldCloseCalendar) {
      setShouldOpenCalendar(false);
    }
  };

  const handleCalendar = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();

    const value = !shouldOpenCalendar;

    setShouldOpenCalendar(value);

    onCalendarClick();
  };

  return (
    <QuestionsFilterContainer>
      <div className="filterLabel">
        <BsFunnel size={14} />
        <span>Filtros</span>
      </div>
      <FilterButtonsContainer>
        {filterButtons.map((button, index) => (
          <FilterButton
            onClick={(e) => {
              e.stopPropagation();
              onFilterButtonClick(button.id);
              setSearchText('');
            }}
            isActive={button.isActive}
            key={button.id}
          >
            <span>{button.title}</span>
            <IconButton isActive={button.isActive}>
              <BsChevronCompactDown strokeWidth={1} size={10} />
            </IconButton>

            {button.shouldDisplayModal && (
              <FilterOptionsContainer onClick={(e) => e.stopPropagation()}>
                {button.shouldDisplayCalendar && (
                  <CalendarContainer onClick={handleCalendar}>
                    <BsCalendar size={12} />
                    <span>Abrir Calendário</span>
                  </CalendarContainer>
                )}

                {button.displaySearchInput && (
                  <div className="searchInputContainer">
                    <MdSearch size={20} />
                    <input
                      type="text"
                      ref={searchRef}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="Buscar"
                    />
                  </div>
                )}
                {button.options?.map((filterOption) => {
                  const shouldDisplayOption =
                    (searchText.length > 0 &&
                      filterOption.label
                        .toLocaleLowerCase()
                        .includes(searchText.toLocaleLowerCase())) ||
                    !searchText;

                  if (!shouldDisplayOption) {
                    return undefined;
                  }

                  return (
                    <div className="optionItem" key={filterOption.id}>
                      {!button.isRadioInput && (
                        <input
                          type="checkbox"
                          onChange={(event) => {
                            event.stopPropagation();
                            onCheckBoxClick({
                              index,
                              checked: event.target.checked,
                              filterOptionId: filterOption.id,
                            });
                          }}
                          checked={filterOption.isChecked}
                        />
                      )}
                      {button.isRadioInput && (
                        <input
                          type="radio"
                          onChange={(event) => {
                            event.stopPropagation();
                            onRadioBoxClick({
                              index,
                              filterOptionId: filterOption.id,
                            });
                          }}
                          name="radioBox"
                          checked={filterOption.isChecked}
                        />
                      )}
                      <span>{filterOption.label}</span>
                    </div>
                  );
                })}
                {button.id !== 0 && button.id !== 3 && (
                  <ClearButtonContainer
                    onClick={() => clearFilterButton(button.id)}
                  >
                    <MdClose size={15} />
                    <span>Limpar</span>
                  </ClearButtonContainer>
                )}
              </FilterOptionsContainer>
            )}
          </FilterButton>
        ))}
        {shouldOpenCalendar && (
          <div className="calendarWrapper">
            <DateRange
              locale={locales.pt}
              onChange={(date) => onChange(date)}
              weekStartsOn={0}
              ranges={[{ ...filterDate }]}
              maxDate={maxDate}
              minDate={minDate}
              showMonthArrow={false}
            />
          </div>
        )}
      </FilterButtonsContainer>
    </QuestionsFilterContainer>
  );
};
