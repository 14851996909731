import React, { useState } from 'react';
import { CarouselDot } from 'pages/Data/List/CarouselDot';
import { CarouselContainer, CarouselDotWrapper } from './styles';

export const Carousel: React.FC = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <CarouselContainer>
      {React.Children.map(children, (child, index) =>
        index === currentIndex ? child : null,
      )}
      <CarouselDotWrapper>
        {React.Children.map(children, (_, index) => (
          <CarouselDot
            active={index === currentIndex}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </CarouselDotWrapper>
    </CarouselContainer>
  );
};
