import React, { useState } from 'react';
import { CountPatientSheetGroupedByStatusResponseDTO } from 'dtos/CountPatientSheetGroupedByStatusResponseDTO';
import { countPatientSheetGroupedByStatus } from 'services/patient';
import { useCurrentEffect } from 'lib/use-current-effect';
import useQuery from 'hooks/query';
import { format } from 'date-fns';
import { BarChart, DataProgressionContainer } from './styles';
import { useWellFareContext } from '../../WellFareContext';

export const DataProgression: React.FC = () => {
  const contextValue = useWellFareContext();
  const [data, setData] =
    useState<CountPatientSheetGroupedByStatusResponseDTO>();
  const query = useQuery();
  const start = query.get('startDate');
  const end = query.get('endDate');

  const startDate = start
    ? new Date(start)
    : (() => {
        const date = new Date();
        date.setMonth(date.getMonth() - 3);
        return date;
      })();
  const startFormat = format(startDate, 'yyyy-MM-dd');
  const endDate = end ? new Date(end) : new Date();
  const endFormat = format(endDate, 'yyyy-MM-dd');

  useCurrentEffect(
    (isCurrent) => {
      countPatientSheetGroupedByStatus({
        icd10: query.get('icd10'),
        sheet: query.get('sheet'),
        start: startFormat,
        end: endFormat,
      }).then((response) => isCurrent() && setData(response));
    },
    [query, startFormat, endFormat],
  );

  if (!data) return <div />;

  const filledPercent =
    data.total && Math.round((data.received / data.total) * 100);
  const notFilledPercent =
    data.total && Math.round((data.notReceived / data.total) * 100);
  return (
    <DataProgressionContainer>
      <div className="headerWrapper">
        <span className="progressionTitle">Progressão de Dados</span>
        {contextValue && (
          <span className="progressionDescription">
            {contextValue.icd10.name} + {contextValue.sheet.name}
          </span>
        )}
      </div>
      <div className="chartContainer">
        <BarChart value={filledPercent} backgroundColor="#2D9CDB">
          <div className="fillBarChart">
            <span className="fillText">
              <span className="fillHighlightedText">{filledPercent}%</span>{' '}
              Dados Preenchidos
            </span>
          </div>
          {filledPercent !== 100 && (
            <span className="barText">
              {data.received} pacientes preencheram
            </span>
          )}
        </BarChart>
        {filledPercent !== 100 && (
          <BarChart value={notFilledPercent} backgroundColor="#E40F0F">
            <div className="fillBarChart">
              <span className="fillText">
                <span className="fillHighlightedText">{notFilledPercent}%</span>{' '}
                Dados Sem Preencher
              </span>
            </div>
            <span className="barText">Restam {data.notReceived} pacientes</span>
          </BarChart>
        )}
      </div>
    </DataProgressionContainer>
  );
};
