import React, { ReactNode } from 'react';
import { Chart } from 'react-google-charts';
import Spinner from 'components/Spinner';
import { ChartContainer, TotalArea } from './styles';

interface ChartProps {
  chartData: [string, number][];
  colors: string[];
  height?: number;
  width?: string | number;
  children: ReactNode;
}

export const PieChart: React.FC<ChartProps> = ({
  chartData,
  colors,
  height = 124,
  width = '100%',
  children,
}) => {
  return (
    <ChartContainer>
      <Chart
        chartType="PieChart"
        loader={<Spinner />}
        data={[['', ''], ...chartData]}
        style={{
          overflowY: 'hidden',
        }}
        height={height}
        width={width}
        options={{
          pieHole: 0.75,
          legend: 'none',
          pieSliceText: 'none',
          colors,
          enableInteractivity: false,
          chartArea: {
            height: '100%',
            width: '100%',
          },
        }}
        rootProps={{ 'data-testid': '2' }}
      />
      <TotalArea>{children}</TotalArea>
    </ChartContainer>
  );
};
