import React, { useEffect, useState } from 'react';
import { BsDownload } from 'react-icons/bs';
import { MdArrowDropDown } from 'react-icons/md';
import { RiFileLine } from 'react-icons/ri';
import { RangeKeyDict } from 'react-date-range';
import { useLocation } from 'react-router-dom';
import { QuestionsFilter } from './QuestionsFilter';
import {
  DownloadContainer,
  DownloadItem,
  DownloadModal,
  QuestionsCardContainer,
  QuestionsContainer,
  TopContainer,
} from './styles';
import {
  allOptionsDefaultValue,
  categoryOptions,
  genderOptions,
  healthConditionOptions,
  questionOptions,
  timePeriodOptions,
} from './filterOptions';
import { QuestionCard } from './QuestionCard';

type OptionData = {
  id: number;
  label: string;
  isChecked: boolean;
};

export interface BoxProps {
  index: number;
  filterOptionId: number;
  checked?: boolean;
}

interface AllOptionsProps {
  selectedLabels: string[];
  checked?: boolean;
  options: OptionData[];
}

interface CheckAllOptionsProps {
  options: OptionData[];
  selectedLabels: string[];
}

export interface QuestionsData {
  id: string;
  question: string;
  options: {
    id: string;
    description: string;
    value: number;
  }[];
}

interface DateValueProps {
  startDate?: Date;
  endDate?: Date;
}

const cardsData: QuestionsData[] = [
  {
    id: '1',
    question: '1. Qual a frequência semanal que pratica atividades físicas?',
    options: [
      {
        id: '1',
        description: 'Realizo atividades físicas de 5 a 7 vezes por semana',
        value: 8,
      },
      {
        id: '2',
        description: 'Realizo atividades físicas de 3 a 4 vezes por semana',
        value: 100,
      },
      {
        id: '3',
        description: 'Realizo atividades físicas de 1 a 2 vezes por semana',
        value: 19,
      },
      {
        id: '4',
        description: 'Não realizo nenhuma atividade',
        value: 4,
      },
    ],
  },
  {
    id: '2',
    question: '2. Hoje você permanece realizando atividades físicas?',
    options: [
      {
        id: '1',
        description: 'Sim, continuo realizando',
        value: 22,
      },
      {
        id: '2',
        description: 'Não, infelizmente parei',
        value: 6,
      },
    ],
  },
];

const initialFilterButtons = [
  {
    id: 0,
    title: 'Gênero: Todos',
    isActive: false,
    options: genderOptions,
    selectedLabels: [...genderOptions.map((option) => option.label)],
    shouldDisplayModal: false,
    formatTitle: (value: string) => `Gênero: ${value}`,
  },
  {
    id: 1,
    title: 'Condição de Saúde: Todos',
    isActive: false,
    options: healthConditionOptions,
    displaySearchInput: true,
    selectedLabels: [...healthConditionOptions.map((option) => option.label)],
    shouldDisplayModal: false,
    formatTitle: (value: string) => `Condição de Saúde: ${value}`,
  },
  {
    id: 2,
    title: 'Questão: Todos',
    isActive: false,
    options: questionOptions,
    displaySearchInput: true,
    selectedLabels: [] as string[],
    isQuestionInput: true,
    shouldDisplayModal: false,
    formatTitle: (value: string) => `Questão: ${value}`,
  },
  {
    id: 3,
    title: 'Período: Mais recente',
    isActive: false,
    options: timePeriodOptions,
    shouldDisplayCalendar: true,
    selectedLabels: [] as string[],
    shouldDisplayModal: false,
    isRadioInput: true,
    formatTitle: (value: string) => `Período: ${value}`,
  },
  {
    id: 4,
    title: 'Categoria: Todos',
    isActive: false,
    options: categoryOptions,
    displaySearchInput: true,
    selectedLabels: [...categoryOptions.map((option) => option.label)],
    shouldDisplayModal: false,
    formatTitle: (value: string) => `Categoria: ${value}`,
  },
];

export type FilterButtonsData = typeof initialFilterButtons;

interface NavigationProps {
  state: {
    period: {
      startDate?: Date;
      endDate?: Date;
    };
  };
}

export const Questions: React.FC = () => {
  const [filterButtons, setFilterButtons] = useState<FilterButtonsData>(
    initialFilterButtons.map((item) => ({ ...item })),
  );

  const [shouldOpenDownloadModal, setShouldOpenDownloadModal] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const startDate = params.get('startDate');
  const endDate = params.get('endDate');
  const [filterDate, setFilterDate] = useState<DateValueProps>({
    startDate: startDate ? new Date(startDate) : undefined,
    endDate: endDate ? new Date(endDate) : undefined,
  });

  useEffect(() => {
    if (filterDate.startDate && filterDate.endDate) {
      setFilterButtons((prev) => {
        const auxFilterButtons = [...prev];

        const foundButton = auxFilterButtons.find((button) => button.id === 3);

        if (foundButton) {
          foundButton.shouldDisplayModal = false;
          foundButton.options.forEach((option) => {
            option.isChecked = false;
          });
          foundButton.title = foundButton.formatTitle('Nenhum');
        }
        return auxFilterButtons;
      });
    }
  }, [filterDate.startDate, filterDate.endDate]);

  const onDownloadModalClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    const value = !shouldOpenDownloadModal;

    setShouldOpenDownloadModal(value);
  };

  const onCalendarClick = () => {
    const auxFilterButtons = [...filterButtons];

    const foundButton = auxFilterButtons.find((button) => button.id === 3);

    if (foundButton) {
      foundButton.shouldDisplayModal = false;
      foundButton.options.forEach((option) => {
        option.isChecked = false;
      });
      foundButton.title = foundButton.formatTitle('Nenhum');
    }

    setFilterButtons(auxFilterButtons);
  };

  const onChangeDate = (rangesByKey: RangeKeyDict) => {
    setFilterDate(rangesByKey.range1);
  };

  const clearFilterButton = (filterButtonId: number) => {
    const auxFilterButtons = [...filterButtons];

    const foundButton = auxFilterButtons.find(
      (filterButton) => filterButton.id === filterButtonId,
    );

    const foundIndex = auxFilterButtons.findIndex(
      (filterButton) => filterButton.id === filterButtonId,
    );

    const { label } = initialFilterButtons[foundIndex].options[1];

    if (foundButton) {
      foundButton.selectedLabels = [label];
      foundButton.options.forEach((button, index) => {
        if (index !== 1) {
          button.isChecked = false;
        } else {
          button.isChecked = true;
        }
      });

      foundButton.title = foundButton.formatTitle(label);
    }

    setFilterButtons(auxFilterButtons);
  };

  const handleCloseAllModals = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.stopPropagation();

    const auxFilters = [...filterButtons];

    auxFilters.forEach((item) => {
      item.shouldDisplayModal = false;
    });

    setFilterButtons(auxFilters);

    setShouldOpenDownloadModal(false);
  };

  const onFilterButtonClick = (id: number) => {
    const isActive = filterButtons.find((item) => item.id === id)?.isActive;

    setFilterButtons((prev) =>
      prev.map((filterButton) => {
        if (filterButton.id !== id) {
          filterButton.shouldDisplayModal = false;
        }

        if (filterButton.id === id) {
          filterButton.isActive = !isActive;
          filterButton.shouldDisplayModal = !isActive;
        }
        return filterButton;
      }),
    );
  };

  const onAllOptionsClick = ({
    options,
    checked,
    selectedLabels,
  }: AllOptionsProps) => {
    options.forEach((option) => {
      option.isChecked = !!checked;

      if (!selectedLabels.includes(option.label) && checked) {
        selectedLabels.push(option.label);
      }
    });
  };

  const checkAllOptions = ({
    options,
    selectedLabels,
  }: CheckAllOptionsProps) => {
    const hasUncheckedOption = options.some(
      (option) => !option.isChecked && option.label !== allOptionsDefaultValue,
    );

    const foundFilterOption = options.find(
      (option) => option.label === allOptionsDefaultValue,
    );

    if (hasUncheckedOption) {
      const foundIndex = selectedLabels.findIndex(
        (value) => value === allOptionsDefaultValue,
      );

      if (foundIndex !== -1) {
        selectedLabels.splice(foundIndex, 1);
      }
    }

    if (
      !hasUncheckedOption &&
      !selectedLabels.includes(allOptionsDefaultValue)
    ) {
      selectedLabels.push(allOptionsDefaultValue);
    }

    if (foundFilterOption) {
      foundFilterOption.isChecked = !hasUncheckedOption;
    }
  };

  const onRadioBoxClick = ({ index, filterOptionId }: BoxProps) => {
    const auxFilterButtons = [...filterButtons];

    const filterOptionIndex = auxFilterButtons[index].options.findIndex(
      (option) => option.id === filterOptionId,
    );

    auxFilterButtons[index].options.forEach((option) => {
      if (option.id !== filterOptionId) {
        option.isChecked = false;
        return;
      }

      option.isChecked = true;
    });

    auxFilterButtons[index].selectedLabels = [
      auxFilterButtons[index].options[filterOptionIndex].label,
    ];

    const newButtonFilterLabel = auxFilterButtons[index].selectedLabels[0];

    const formattedTitle =
      auxFilterButtons[index].formatTitle(newButtonFilterLabel);

    auxFilterButtons[index].title = formattedTitle;

    setFilterButtons(auxFilterButtons);
  };

  const onQuestionBoxClick = (filterButton: FilterButtonsData[number]) => {
    const numbers = '0123456789';

    let selectedOptions = '';

    const filteredOptions = filterButton.options.filter(
      (filter) => filter.label !== allOptionsDefaultValue,
    );

    filteredOptions.forEach((option, index) => {
      if (option.isChecked && option.label !== allOptionsDefaultValue) {
        selectedOptions += String(index);
      }
    });

    const isSequence = numbers.indexOf(String(selectedOptions)) !== -1;

    if (isSequence && selectedOptions.length === 1) {
      const value = String(Number(selectedOptions[0]) + 1);

      filterButton.title = filterButton.formatTitle(value);
      return;
    }

    if (isSequence) {
      const minValue = Number(selectedOptions[0]) + 1;
      const maxValue = Number(selectedOptions[selectedOptions.length - 1]) + 1;

      filterButton.title = filterButton.formatTitle(`${minValue}-${maxValue}`);
      return;
    }

    if (!isSequence && selectedOptions.length > 1) {
      const values = selectedOptions.split('').map((option) => Number(option));
      const slicedValues = values
        .sort((a, b) => a - b)
        .slice(0, 3)
        .map((item) => item + 1);
      const sortedValues = slicedValues.join(',');
      const formattedValues = sortedValues.replace(/,(?=[^,]+$)/, ' e ');

      const finalValue =
        selectedOptions.length > 3 ? `${formattedValues}...` : formattedValues;

      filterButton.title = filterButton.formatTitle(finalValue);
    }
  };

  const onCheckBoxClick = ({ index, filterOptionId, checked }: BoxProps) => {
    const auxFilterButtons = [...filterButtons];

    const filterButton = auxFilterButtons[index];

    if (!checked && filterButton.selectedLabels.length === 1) {
      return;
    }

    const filterOptionIndex = filterButton.options.findIndex(
      (option) => option.id === filterOptionId,
    );

    const selectedLabel = filterButton.options[filterOptionIndex].label;

    if (selectedLabel === allOptionsDefaultValue && !checked) {
      return;
    }

    const filter = filterButton.options[filterOptionIndex];

    if (checked) {
      filterButton.selectedLabels.push(filter.label);
    } else {
      const selectedIndex = filterButton.selectedLabels.findIndex(
        (item) => item === filter.label,
      );

      filterButton.selectedLabels.splice(selectedIndex, 1);
    }

    if (selectedLabel === allOptionsDefaultValue) {
      const { options } = filterButton;
      const { selectedLabels } = filterButton;

      onAllOptionsClick({ options, selectedLabels, checked });
    } else {
      filterButton.options[filterOptionIndex].isChecked = !!checked;
    }

    const { options, selectedLabels } = filterButton;

    checkAllOptions({ options, selectedLabels });

    const newButtonFilterLabel = filterButton.selectedLabels[0];

    const formattedTitle = filterButton.formatTitle(newButtonFilterLabel);
    const additionalAmount =
      filterButton.selectedLabels.filter(
        (selectedValue) => selectedValue !== allOptionsDefaultValue,
      ).length - 1;

    const totalAmount = filterButton.options.length - 1;
    const selectedValuesAmount = additionalAmount + 1;

    if (selectedValuesAmount >= totalAmount) {
      filterButton.title = initialFilterButtons[index].title;
      setFilterButtons(auxFilterButtons);

      return;
    }

    if (filterButton.isQuestionInput) {
      onQuestionBoxClick(filterButton);
    } else {
      filterButton.title = `${formattedTitle} ${
        additionalAmount > 0 ? `+${additionalAmount}` : ''
      }`;
    }

    setFilterButtons(auxFilterButtons);
  };

  return (
    <QuestionsContainer onClick={(e) => handleCloseAllModals(e)}>
      <TopContainer>
        <QuestionsFilter
          filterButtons={filterButtons}
          filterDate={filterDate}
          onFilterButtonClick={onFilterButtonClick}
          onCheckBoxClick={onCheckBoxClick}
          onRadioBoxClick={onRadioBoxClick}
          clearFilterButton={clearFilterButton}
          onCalendarClick={onCalendarClick}
          onChangeDate={onChangeDate}
        />
        <DownloadContainer onClick={onDownloadModalClick}>
          <BsDownload size={12} />
          <span>Download</span>
          <MdArrowDropDown size={12} />

          {shouldOpenDownloadModal && (
            <DownloadModal>
              <DownloadItem>
                <RiFileLine />
                <span>Modelo PDF</span>
              </DownloadItem>
              <DownloadItem>
                <RiFileLine />
                <span>Modelo Excel</span>
              </DownloadItem>
            </DownloadModal>
          )}
        </DownloadContainer>
      </TopContainer>
      <QuestionsCardContainer>
        {cardsData.map((card) => (
          <QuestionCard
            key={card.id}
            question={card.question}
            options={card.options}
          />
        ))}
      </QuestionsCardContainer>
    </QuestionsContainer>
  );
};
