import { GetMostCommonIcd10DTO } from 'dtos/GetMostCommonICD10DTO';
import { api } from 'services/api';

export const GetMostCommonIcd10 = async (): Promise<
  GetMostCommonIcd10DTO[]
> => {
  const { data } = await api.get<GetMostCommonIcd10DTO[]>(
    'icd10/listMostCommon',
  );
  return data;
};
