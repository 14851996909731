import React, { useEffect, useState } from 'react';
import ButtonFilterRangeDate, {
  DateValueProps,
} from 'components/ButtonFilterRangeDate';
import { useField } from '@unform/core';
import { Error } from 'components/Input/styles';
import { FiAlertCircle } from 'react-icons/fi';
import {
  InputDateContainer as Container,
  InputLabel as Label,
  InputDateWrapper as Wrapper,
} from './styles';

interface InputProps {
  name: string;
  label?: string;
  withPlaceholder?: boolean;
}

export const InputDateRange: React.FC<InputProps> = ({
  name,
  label,
  withPlaceholder = false,
}) => {
  const [dateRange, setDateRange] = useState<DateValueProps>({});
  const { fieldName, registerField, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => dateRange,
    });
  }, [fieldName, registerField, dateRange]);

  return (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <Container>
        <ButtonFilterRangeDate
          onChange={(value) => {
            clearError();
            setDateRange(value);
          }}
          dateValue={dateRange}
          withLabel={withPlaceholder}
          withMaxWidth={false}
        />
        {error && (
          <div>
            <Error title={error}>
              <FiAlertCircle color="#c53030" size={20} />
            </Error>
          </div>
        )}
      </Container>
    </Wrapper>
  );
};
