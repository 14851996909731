import styled, { css } from 'styled-components';

interface BarChartProps {
  backgroundColor: '#2D9CDB' | '#E40F0F';
  value: number;
}

export const DataProgressionContainer = styled.div`
  grid-area: dataProgression;

  background: #fff;
  border-radius: 6px;

  padding: 31px 31px 24px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

  .headerWrapper {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  .headerWrapper .progressionTitle {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #4f4f4f;
  }

  .headerWrapper .progressionDescription {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #4f4f4f;
  }

  .chartContainer {
    margin-top: 7px;

    display: flex;
    flex-direction: column;
    gap: 14px;
  }
`;

export const BarChart = styled.div<BarChartProps>`
  width: 100%;
  border-radius: 32px;

  ${({ backgroundColor }) => {
    return css`
      background: ${backgroundColor === '#2D9CDB'
        ? 'rgba(45, 156, 219, 0.55)'
        : 'rgba(228, 15, 15, 0.55)'};
    `;
  }}

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  min-height: 28px;

  .fillBarChart {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: ${({ value }) => value}%;
    min-height: 28px;
    border-radius: 32px;
    z-index: 999;

    background: ${({ backgroundColor }) => backgroundColor};
  }

  .fillText {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    color: #ffffff;
    white-space: nowrap;

    margin-left: 19px;
  }

  .fillHighlightedText {
    font-weight: 700;
  }

  .barText {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0em;
    color: rgba(255, 255, 255, 0.55);
    margin-right: 19px;
  }
`;
