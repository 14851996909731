import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const HeaderAnamnese = styled.div`
  display: grid;
  grid-template: minmax(300px, auto) / repeat(7, 1fr);
  gap: 24px;
  grid-template-areas: 'pr pr pr pr pr pa pa';
  grid-auto-rows: auto;
  margin-bottom: 24px;
  margin-top: 20px;
`;

export const SectionAnamnese = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  height: 100%;
  margin-top: 15px;
  border-radius: 6px;
  padding: 30px 40px;
`;

export const ConsultArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 50px;

  @media (max-width: 1233px) {
    margin-bottom: 50px;
    margin-right: 0;
  }
`;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Subtitle = styled.h2`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${({ theme }) => theme.colors.graySecondary};

  margin: 15px 0 10px;
`;

export const VideoBox = styled.div<{ isVisible?: boolean }>`
  max-width: 640px;
  ${({ isVisible }) =>
    !isVisible &&
    css`
      visibility: hidden;
    `}
`;

export const FooterAnamnese = styled.footer`
  margin: 10px 0px 0px;
  display: flex;
  justify-content: flex-end;

  button + button {
    margin-left: 10px;
  }

  @media (max-width: 470px) {
    justify-content: center;
  }
`;
