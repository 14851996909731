import styled, {
  css,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import { Link } from 'react-router-dom';

import { Container as GridCardContainer } from '../../../../components/GridCard/styles';
import BraceletStatusTypeEnum from '../../../../enums/BraceletStatusTypeEnum';

interface BandStatusProps {
  status: BraceletStatusTypeEnum;
}

interface RiskDegreeColorProps {
  riskDegreeColor?: string;
}

type CurrentCustomerStatusType = 'info' | 'danger';

interface CurrentCustomerStatusProps {
  type: CurrentCustomerStatusType;
}

type ValuesVariation<T extends PropertyKey> = {
  [key in T]: string | FlattenInterpolation<ThemeProps<any>>;
};

interface GridPropsPersonalDataCard {
  gridArea: 'pictureProfile' | 'heading' | 'options' | 'table';
}

const currentCustomerStatusVariation: ValuesVariation<CurrentCustomerStatusType> =
  {
    info: css`
      background-color: ${({ theme }) => `${theme.colors.grayAlternative}60`};
      color: #707683;
    `,
    danger: css`
      background-color: rgba(234, 118, 118, 0.2);
      color: #dd3636;
    `,
  };

const bandStatusColors: ValuesVariation<BraceletStatusTypeEnum> = {
  [BraceletStatusTypeEnum.ACTIVE]: '#40ec24',
  [BraceletStatusTypeEnum.DISABLED]: '',
};

export const Container = styled(GridCardContainer)`
  padding-top: 32px;
  display: flex;
  padding-bottom: 16px;

  @media (max-width: 1260px) {
    padding-top: 16px;
  }

  @media (max-width: 1100px) {
    padding-top: 16px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template: auto / 250px 1fr 50px;
  grid-template-areas:
    'pictureProfile heading options'
    'pictureProfile table table';

  @media (max-width: 1681px) {
    grid-template: auto / 0.15fr 1fr 50px;
    grid-template-areas:
      'pictureProfile heading options'
      'pictureProfile table table';
    align-content: flex-start;
    grid-row-gap: 20px;
    padding: 0 24px;
  }

  @media (max-width: 1441px) {
    grid-template: auto / 0.15fr 1fr 0.05fr;
    grid-template-areas:
      'pictureProfile heading options'
      'pictureProfile table table';
    align-content: flex-start;
    padding: 0 24px;
  }

  @media (max-width: 1260px) {
    grid-template: auto / 0.25fr 1fr 0.1fr;
    grid-template-areas:
      'pictureProfile heading options'
      'table table table';
    align-content: flex-start;
    padding: 0 16px;
  }

  @media (max-width: 1200px) {
    grid-template: auto / 0.2fr 1fr 0.1fr;
    grid-template-areas:
      'pictureProfile heading options'
      'table table table';
    align-content: flex-start;
    padding: 0 16px;
  }

  @media (max-width: 1024px) {
    grid-template: auto / 0.2fr 1fr 0.1fr;
    grid-template-areas:
      'pictureProfile heading options'
      'table table table';
    align-content: flex-start;
    padding: 0 16px;
  }
`;

export const BandStatus = styled.div<BandStatusProps>`
  padding-top: 13px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;

    font-feature-settings: 'pnum' on, 'lnum' on;

    color: ${({ theme }) => theme.colors.graySecondary};
  }

  > div {
    width: 14px;
    height: 14px;
    background: ${({ status }) => bandStatusColors[status]};
    border-radius: 50%;
  }
`;

export const BandRecordUsageButton = styled.button`
  padding-top: 5px;

  border: none;
  background: transparent;

  color: ${({ theme, disabled }) =>
    disabled ? `${theme.colors.grayAlternative}80` : theme.colors.primary};
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `};
  font-size: 13px;
  font-weight: 600;
`;

export const ProfilePicture = styled.div<GridPropsPersonalDataCard>`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: flex-start;
  grid-area: ${({ gridArea }) => gridArea};

  width: 200px;
  margin-left: 25px;
  margin-right: 25px;

  img {
    background-color: #f5f3a1;
    height: 175px;
    width: 175px;
    border-radius: 100%;
  }

  @media (max-width: 1680px) {
    margin-left: 5px;
    margin-right: 18px;
    margin-bottom: 15px;
    width: 170px;

    img {
      background-color: #f5f3a1;
      height: 170px;
      width: 170px;
      border-radius: 100%;
    }
  }

  @media (max-width: 1440px) {
    margin-left: 5px;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 160px;

    img {
      background-color: #f5f3a1;
      height: 160px;
      width: 160px;
      border-radius: 100%;
    }
  }

  @media (max-width: 1260px) {
    margin-left: 5px;
    margin-right: 15px;
    margin-bottom: 0;
    width: 140px;

    img {
      background-color: #f5f3a1;
      height: 140px;
      width: 140px;
      border-radius: 100%;
    }
  }

  @media (max-width: 1200px) {
    margin-left: 5px;
    margin-right: 15px;
    margin-bottom: 0;
    width: 125px;

    img {
      background-color: #f5f3a1;
      height: 125px;
      width: 125px;
      border-radius: 100%;
    }
  }
  @media (max-width: 1100px) {
    margin-left: 5px;
    margin-right: 15px;
    margin-bottom: 0;
    width: 125px;

    img {
      background-color: #f5f3a1;
      height: 125px;
      width: 125px;
      border-radius: 100%;
    }
  }

  @media (max-width: 1024px) {
    margin-left: 5px;
    margin-right: 12px;
    margin-bottom: 0;
    width: 120px;

    img {
      background-color: #f5f3a1;
      height: 120px;
      width: 120px;
      border-radius: 100%;
    }
  }

  @media (max-width: 924px) {
    margin-left: 5px;
    margin-right: 12px;
    margin-bottom: 0;
    width: 60px;

    img {
      background-color: #f5f3a1;
      height: 60px;
      width: 60px;
      border-radius: 100%;
    }
  }
`;

export const CardHeader = styled.header<GridPropsPersonalDataCard>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  grid-area: ${({ gridArea }) => gridArea};

  width: 100%;

  button {
    padding-top: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    font-size: 12px;

    color: ${({ theme }) => theme.colors.primary};
    border: none;
    background: transparent;
  }
`;

export const NameAndRiskDegree = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  h1 {
    width: 100%;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.graySecondary};
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const CardContent = styled.div<GridPropsPersonalDataCard>`
  display: flex;
  grid-area: ${({ gridArea }) => gridArea};
  padding-bottom: 4px;
  padding-right: 16px;
  gap: 8px;

  @media (max-width: 1260px) {
    padding-right: 16px;
    gap: 1.3rem;
    align-content: space-between;
  }

  @media (max-width: 1024px) {
    gap: 1.3rem;
    flex-wrap: wrap;
    align-content: space-between;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 12px;
    align-content: space-between;
    padding-bottom: 8px;
  }
`;

export const ContentTable = styled.div`
  display: grid;
  grid-template-rows: repeat(4, auto);
  grid-auto-rows: auto;
  column-gap: 1rem;
  width: 100%;

  &:last-child {
    display: flex;
    flex-direction: column;

    justify-content: flex-end;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 58px 1fr;
  grid-auto-rows: auto;
  gap: 15px;

  > strong {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    text-align: right;

    font-feature-settings: 'pnum' on, 'lnum' on;

    color: ${({ theme }) => theme.colors.graySecondary};
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    white-space: nowrap;

    font-feature-settings: 'pnum' on, 'lnum' on;

    color: ${({ theme }) => theme.colors.graySecondary};
  }

  @media (max-width: 1300px) {
    > strong,
    span {
      line-height: 22px;
    }
  }

  @media (max-width: 1200px) {
    > strong,
    span {
      line-height: 22px;
    }
  }

  @media (max-width: 1024px) {
    > strong,
    span {
      line-height: 18px;
    }
  }
`;

export const Risk = styled.div<RiskDegreeColorProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: min-content;

  > p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    font-feature-settings: 'pnum' on, 'lnum' on;

    white-space: nowrap;

    color: #707683;
  }

  > span {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 18px;

    font-feature-settings: 'pnum' on, 'lnum' on;

    color: ${({ theme }) => theme.colors.white};
    background-color: ${(props) => props.riskDegreeColor};

    width: 34px;
    height: 34px;
    border-radius: 100%;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 18px;
    margin-right: 16px;
  }

  @media (max-width: 1100px) {
    > p {
      font-size: 12px;
    }

    > span {
      font-size: 16px;

      width: 28px;
      height: 28px;
      border-radius: 100%;
      margin-left: 8px;
    }
  }
`;

export const Options = styled.div<GridPropsPersonalDataCard>`
  grid-area: ${({ gridArea }) => gridArea};
  > div {
    margin-right: 30px;
  }
`;

export const CurrentCustomerStatus = styled.div<CurrentCustomerStatusProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;

  border-radius: 0 0 6px 6px;
  padding: 8px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  ${({ type }) => currentCustomerStatusVariation[type]};

  svg {
    font-size: 14px;
    margin-right: 5px;
    vertical-align: middle;
  }

  > button {
    border: 0;
    background: transparent;
    font-weight: 800;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1f80b7;
    margin-left: 20px;

    &:hover {
      color: #1f80b799;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed !important;

      &:hover {
        color: #1f80b7;
      }
    }
  }
`;

export const SeeProfileLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};

  text-decoration: none;

  span {
    font-size: 12px;
    margin-left: 10px;
    text-transform: uppercase;
  }

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;

export const InstitutionDetails = styled.div`
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;

  margin-top: 10px;
  color: ${({ theme }) => theme.colors.grayAlternative};
  width: 100%;

  span {
    font-weight: 500;
    font-size: 14px;
    margin-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
