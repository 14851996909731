import React, { useEffect, useState } from 'react';

import MainAreasEnum from 'enums/MainAreasEnum';

import { ElementPageTree } from 'components/PageTree/types';
import PageTree from 'components/PageTree';
import DashboardTemplate from 'templates/Dashboard';
import { GetMostCommonIcd10DTO } from 'dtos/GetMostCommonICD10DTO';
import { GetSheetCountDTO } from 'dtos/GetSheetCountDTO';
import { GetMostCommonIcd10 } from 'services/icd10';
import { GetSheetCount } from 'services/sheet';
import { AdvancedAnalysisButton, DataSection, SwapIcon } from './styles';
import { DataSectionCard } from './DataSectionCard';
import { DataChartCard } from './DataChartCard';
import { DataSearchForm } from './DataSearchForm';
import { DataProgressionCard } from './DataProgressionCard';

const pages: ElementPageTree[] = [
  { name: 'Administração' },
  { name: 'Dados de Saúde' },
];

export const DataList: React.FC = () => {
  const [healthConditions, setHealthConditions] = useState<
    GetMostCommonIcd10DTO[]
  >([]);
  const [categories, setCategories] = useState<GetSheetCountDTO[]>([]);
  const getPercentages = async () => {
    try {
      const [conditions, category] = await Promise.all([
        await GetMostCommonIcd10(),
        await GetSheetCount(),
      ]);

      setHealthConditions(conditions);
      setCategories(category);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPercentages();
  }, []);
  return (
    <DashboardTemplate name={MainAreasEnum.MANAGEMENT}>
      <PageTree pages={pages} />
      <AdvancedAnalysisButton>
        <SwapIcon color="white" />
        Análise Avançada
      </AdvancedAnalysisButton>

      <DataSearchForm />

      <DataSection>
        <div>
          <DataChartCard />
          <DataProgressionCard />
        </div>
        <DataSectionCard
          title="Condição de saúde"
          columnText="Condição de saúde"
          subtitle="Quantidade total de pacientes"
          data={healthConditions.map((condition) => ({
            icd10: condition.icd10Id,
            amount: condition.patients,
            name: condition.icd10Name,
            percent: condition.percentage,
          }))}
          colorizedPercent={false}
        />
        <DataSectionCard
          title="Categoria"
          columnText="Categoria"
          subtitle="Todos os dados das categorias"
          additionalText="preenchidas"
          data={categories.map((category) => ({
            sheet: category.sheetId,
            amount: category.totalSheets,
            name: category.sheetName,
            percent: category.percentage,
          }))}
        />
      </DataSection>
    </DashboardTemplate>
  );
};
