import styled from 'styled-components';

export const GeneralDataContainer = styled.div`
  grid-area: generalData;

  background: #fff;
  border-radius: 6px;

  padding: 31px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

  .generalDataHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 68px;
    width: 100%;
    margin-bottom: 20px;
  }

  .generalDataHeader .dataWrapper {
    background: green;
  }

  .generalDataPeriod {
    color: #707683;
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
  }

  .headerWrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .headerWrapper .generalDataTitle {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    color: #4f4f4f;
  }
`;

export const HealthDataWrapper = styled.div`
  padding: 5px 8px;
  border-radius: 6px;
  background: #2d9cdb4d;

  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    color: #4f4f4f;

    font-family: Raleway;
    font-size: 10px;
    font-weight: 600;
  }
`;
