import { api } from '../api';

interface Return {
  name: string;
  patientCount: number;
}

export const getIcd10ById = async (id?: string): Promise<Return> => {
  const { data } = await api.get<Return>(`/icd10/${id}`);

  return data;
};
