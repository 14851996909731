import styled from 'styled-components';

export const GeneralResultContainer = styled.div`
  display: grid;
  grid-template-areas:
    'dataSearch dataSearch'
    'generalData insights'
    'generalData patientData'
    'dataProgression dataProgression'
    'patientList patientList'
    'smartBand smartBand';

  grid-template-columns: 1fr 1fr;

  grid-gap: 24px;

  width: 100%;
  min-width: 864px;
  margin-top: 40px;
  margin-left: 20px;
`;

export const FormContainer = styled.div`
  grid-area: dataSearch;
`;
