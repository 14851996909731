import styled from 'styled-components';
import { ReactComponent as Search } from 'assets/images/search.svg';
import { Card } from 'styled/Card';
import { Form } from '@unform/web';
import ButtonBlue from 'components/ButtonBlue';

export const DataListInputSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const InputsCard = styled(Card)`
  display: grid;
  gap: 23px;

  width: 100%;
`;

export const DataListForm = styled(Form)`
  display: flex;
  gap: 36px;
  flex-wrap: wrap;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 6px;
  color: ${({ theme }) => theme.colors.grayTertiary};
  font-size: 12px;
  font-weight: 500;
`;

export const SheetInputWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 345px;
`;
export const ConditionInputWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 250px;
`;

export const SearchButton = styled(ButtonBlue)`
  height: 39px;
  flex-basis: 146px;
  flex-grow: 1;
  align-self: flex-end;

  @media screen and (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    max-width: 100%;
  }
`;

export const SearchIcon = styled(Search)`
  margin-right: 8px;
`;
