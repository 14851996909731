import styled from 'styled-components';

export const GenderTotalArea = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > p {
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.graySecondary};
    text-align: center;
  }

  > p > strong {
    font-size: 18px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.graySecondary};
    text-align: center;
  }
`;

export const ChartContainer = styled.div``;

export const DataChartCardWrapper = styled.div`
  width: 100%;
  overflow-y: visible;
  position: relative;
  margin-top: 12px;

  margin-bottom: 28px;
`;

export const DataSectionCardTableDataName = styled.td`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.graySecondary};

  > span {
    font-weight: 500;
  }
`;

export const DataChartCardTd = styled(DataSectionCardTableDataName)`
  display: flex;
  align-items: center;
`;
