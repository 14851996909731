import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import {
  HorizontalBarChartContainer as Container,
  HorizontalBarChartBar,
} from './styles';

interface HorizontalBarChartProps {
  data: {
    name: string;
    amount: number;
    color?: string;
  }[];
  colors?: string[];
}

export const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({
  data,
  colors,
}) => {
  const theme = useTheme();

  const barWidths = useMemo(() => {
    const amount = data.map((item) => item.amount).sort((a, b) => b - a);
    const [highestValue] = amount;

    const widths = [];
    for (let i = 0; i < data.length; i++) {
      widths.push((data[i].amount / highestValue) * 100);
    }

    return widths;
  }, [data]);

  return (
    <Container>
      {data.map((item, index) => (
        <HorizontalBarChartBar
          fillColor={item.color || colors?.[index] || theme.colors.primary}
          width={barWidths[index]}
        />
      ))}
    </Container>
  );
};
