import React from 'react';
import personAvatar from 'assets/images/avatar.svg';
import {
  AvatarContainer,
  PatientInfoWrapper,
  PatientListContainer,
} from './styles';
import { PatientsData } from '..';

interface PatientListProps {
  patients?: PatientsData;
  onItemClick: (id: number) => void;
}

export const PatientList: React.FC<PatientListProps> = ({
  patients,
  onItemClick,
}) => {
  return (
    <PatientListContainer>
      <table id="patients">
        <thead>
          <tr>
            <th>Pacientes</th>
            <th>Data</th>
            <th>Condição de Saúde</th>
          </tr>
        </thead>

        <tbody>
          {patients?.map(
            ({
              patientId: id,
              name,
              sheetDate: date,
              icd10Description: healthCondition,
            }) => (
              <tr key={id}>
                <td className="patientInfoColumn">
                  <PatientInfoWrapper>
                    <AvatarContainer>
                      <img src={personAvatar} alt="Foto de perfil do avatar" />
                    </AvatarContainer>
                    <span className="patientName">{name}</span>
                  </PatientInfoWrapper>
                </td>
                <td>
                  <span className="columnInfoText">
                    {new Date(date).toLocaleDateString()}
                  </span>
                </td>
                <td>
                  {healthCondition && (
                    <span className="columnInfoText">{healthCondition}</span>
                  )}
                  {!healthCondition && (
                    <>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <span className="columnInfoText">-</span>
                    </>
                  )}
                </td>
              </tr>
            ),
          )}
        </tbody>
      </table>
      <button type="button" onClick={() => onItemClick(2)}>
        Ver todos
      </button>
    </PatientListContainer>
  );
};
