import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const BoardContainer = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    font-family: Raleway;
    font-weight: 400;
    font-size: 10px;
    color: #828282;
  }

  > span {
    font-family: Raleway;
    font-weight: 400;
    font-size: 10px;
    color: #828282;
  }
`;
