import React, { useState } from 'react';

import { BulletPoint } from 'components/BulletPoint';
import { PieChart } from 'components/PieChart';
import { GetPatientCountGroupedByGenderResponseDTO } from 'dtos/GetPatientCountGroupedByGenderResponseDTO';
import useQuery from 'hooks/query';
import { useCurrentEffect } from 'lib/use-current-effect';
import { getPatientCountGroupedByGender } from 'services/patient';
import Spinner from 'components/Spinner';
import { abbreviateNumber } from 'utils/abbreviateNumber';
import {
  DataSectionCardTable as Table,
  DataSectionCardTableDataName as Name,
} from '../DataSectionCard/styles';
import {
  ChartContainer,
  DataChartCardTd as Td,
  DataChartCardWrapper as Wrapper,
  GenderTotalArea as TotalArea,
} from './styles';

interface GenderChartProps {
  startDate: string;
  endDate: string;
}

export const GenderChart: React.FC<GenderChartProps> = ({
  startDate,
  endDate,
}) => {
  const [data, setData] = useState<GetPatientCountGroupedByGenderResponseDTO>();
  const query = useQuery();

  useCurrentEffect(
    (isCurrent) => {
      getPatientCountGroupedByGender({
        icd10: query.get('icd10'),
        sheet: query.get('sheet'),
        start: startDate,
        end: endDate,
      }).then((response) => isCurrent() && setData(response));
    },
    [query, startDate, endDate],
  );

  if (!data) return <Spinner />;
  return (
    <ChartContainer>
      <Wrapper>
        <PieChart
          chartData={[
            ['Pacientes Femininos', data.female],
            ['Pacientes Masculinos', data.male],
          ]}
          colors={['#DB3E5A', '#EEB711']}
        >
          <TotalArea>
            <p>Total</p>
            <p>
              <strong>{abbreviateNumber(data.total)}</strong>
            </p>
          </TotalArea>
        </PieChart>
      </Wrapper>

      <div>
        <Table>
          <tbody>
            <tr>
              <Td>
                <BulletPoint color="#DB3E5A" style={{ marginRight: 6 }} />
                Pacientes Femininos
              </Td>

              <Name>
                {data.female} <span>Pacientes</span>
              </Name>
            </tr>
            <tr>
              <Td>
                <BulletPoint color="#EEB711" style={{ marginRight: 6 }} />
                Pacientes Masculinos
              </Td>

              <Name>
                {data.male} <span>Pacientes</span>
              </Name>
            </tr>
          </tbody>
        </Table>
      </div>
    </ChartContainer>
  );
};
