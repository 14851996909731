import styled from 'styled-components';

interface FilterButtonProps {
  isActive: boolean;
}

export const QuestionsFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  .filterLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    color: #4f4f4f;
  }

  .filterLabel span {
    margin-left: 8px;
  }
`;

export const FilterButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 0 28px;
  overflow: scroll;

  width: 100%;

  .calendarWrapper {
    position: absolute;
    right: 200px;
    z-index: 999;
    top: 160px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const FilterButton = styled.div<FilterButtonProps>`
  border: 1px solid ${({ isActive }) => (isActive ? '#2d9cdb' : '#ffffff')};
  border-radius: 50px;
  padding: 10px;
  background: ${({ isActive }) => (isActive ? '#2d9cdb' : '#ffffff')};
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;

  & + div {
    margin-left: 16px;
  }

  span {
    font-size: 12px;
    font-weight: 600;
    font-family: Raleway;
    color: ${({ isActive }) => (isActive ? '#ffffff' : '#2d9cdb')};
    white-space: nowrap;
  }

  .iconContainer {
    padding: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }

  svg {
    color: ${({ isActive }) => (isActive ? '#ffffff' : '#2d9cdb')};
  }
`;

export const IconButton = styled.div<FilterButtonProps>`
  padding: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;

  svg {
    color: ${({ isActive }) => (isActive ? '#ffffff' : '#2d9cdb')};
  }
`;

export const FilterOptionsContainer = styled.div`
  border: 1px solid #2d9cdb;
  border-radius: 6px;
  padding: 24px;
  z-index: 999;
  position: absolute;

  top: 160px;

  background: #ffffff;

  min-width: 326px;

  .optionItem {
    display: flex;
    flex-direction: row;
    gap: 12px;

    & + .optionItem {
      margin-top: 10px;
    }

    span {
      font-family: Raleway;
      font-weight: 600;
      font-size: 14px;
      color: #707683;
    }
  }

  .searchInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6.5px;

    margin-bottom: 16px;

    svg {
      color: #d5d5d5;
    }

    input::placeholder {
      font-family: Raleway;
      font-weight: 600;
      font-size: 14px;
      color: #707683;
    }

    input {
      border: none;
    }

    input:focus {
      outline: none;
    }
  }
`;

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  margin-bottom: 16px;

  span {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    color: #2d9cdb;
  }

  svg {
    color: #2d9cdb;
  }
`;

export const ClearButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  margin-top: 12px;

  span {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    color: #4f4f4f;
  }

  svg {
    color: #4f4f4f;
  }
`;
