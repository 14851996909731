import React from 'react';
import bulb from 'assets/images/bulb.svg';
import { InsightsContainer } from './styles';

export const Insights: React.FC = () => {
  return (
    <InsightsContainer>
      <div className="titleContainer">
        <span className="insightTitle">Insights</span>
        <img src={bulb} alt="insights" />
      </div>
      <span className="insightText">
        <span className="insightHighlightedText">33%</span> dos pacientes com a
        condição de saúde{' '}
        <span className="insightHighlightedText">Hipertensão</span> tem pelo
        <span className="insightHighlightedText">
          {' '}
          menos mais uma condição de saúde associada.
        </span>
      </span>
    </InsightsContainer>
  );
};
