interface TruncateTextProps {
  maxLength: number;
  text: string;
}

const truncateText = ({ maxLength, text }: TruncateTextProps): string => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
};

export default truncateText;
