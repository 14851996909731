import styled from 'styled-components';

export const CarouselContainer = styled.div`
  width: 100%;

  overflow: hidden;
`;

export const CarouselDotWrapper = styled.div`
  display: flex;

  justify-content: center;
  margin-top: 20px;

  button:not(:first-child) {
    margin-left: 15px;
  }
`;
