import styled from 'styled-components';

interface Props {
  active?: boolean;
}

export const CarouselDotButton = styled.button<Props>`
  height: 10px;
  width: 10px;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.primary : 'transparent'};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
`;
