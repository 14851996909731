import styled from 'styled-components';
import ReactSelect, { CSSObjectWithLabel } from 'react-select';
import { ReactComponent as Search } from 'assets/images/search.svg';
import ButtonBlue from 'components/ButtonBlue';

interface SelectProps {
  isErrored: boolean;
  isFocused: boolean;
  isCentered?: boolean;
}

export const ResultPerPatientContainer = styled.div`
  margin-left: 20px;
  width: 100%;
`;

export const SearchFilterContainer = styled.div`
  border-radius: 6px;
  background: #fff;
  padding: 30px 67px 45px 30px;
  width: 100%;
  height: 259px;

  margin-top: 40px;

  .title {
    color: #4f4f4f;
    font-weight: 600;
    font-size: 18px;
    font-family: Raleway;
  }

  .inputDateWrapper {
    width: 326px;
  }
`;

export const ListInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 16px;
`;

export const LeftInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  .orderSelectorLabel {
    font-family: Raleway;
    font-size: 12px;
    color: #4f4f4f;
    font-weight: 400;
  }
`;

export const RightInfo = styled.div`
  span {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    color: #707683;
  }

  .displayedResults {
    font-weight: 800;
  }

  .totalResults {
    font-weight: 800;
    color: #2d9cdb;
  }
`;

export const Select = styled(ReactSelect).attrs({
  styles: {
    control: (base: CSSObjectWithLabel) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
    }),
  },
})<SelectProps>`
  background: transparent;
  border: none;
  min-width: 125px;

  input:focus {
    border: 0,
    box-shadow: none;
  }

  > div:first-of-type {
    border: none;
    min-height: 42px !important;
    background: transparent;
  }

  .react-select__single-value {
    font-family: Raleway;
    color: #707683;
    font-size: 12px;
    font-feature-settings: 'pnum' on, 'lnum' on;
  }

  svg {
    color: #707683;
    stroke-width: 1px;
  }

  svg path {
    stroke-width: 1px;
  }

`;

export const SearchIcon = styled(Search)`
  margin-right: 8px;
`;

export const SearchButton = styled(ButtonBlue)`
  height: 39px;
  width: 146px;
  align-self: flex-end;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 25px;
  margin-bottom: 38px;

  > div:first-of-type {
    height: 40px;
    width: 100%;
  }
`;

export const SearchButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const Order = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 12px;
    color: #4f4f4f;
  }
`;
