import styled from 'styled-components';

export const PatientDataContainer = styled.div`
  grid-area: patientData;

  background: #fff;
  border-radius: 6px;

  padding: 31px;

  display: flex;
  flex-direction: column;
  gap: 18px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

  .headerWrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .patientDataTitle {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0em;
    color: #4f4f4f;
  }

  .patientDataText {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0em;
    color: #2c7be5;
  }

  .infoNameText {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    color: #4f4f4f;

    max-width: 250px;
  }

  .infoDescriptionText {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.44px;
    letter-spacing: 0em;
  }
`;

export const PatientInfoWrapper = styled.div`
  .infoLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    padding: 11px 0;
  }

  .infoLine:first-of-type {
    border-bottom: 1px solid #efefef;
  }
`;
