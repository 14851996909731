import styled from 'styled-components';

export const Container = styled.div`
  padding: 80px 50px 20px 130px;

  @media (max-width: ${({ theme }) => theme.gridBreakpoints.sm}) {
    padding: 120px 20px 20px;
  }

  @media (max-width: 1200px) {
    padding: 50px 40px 20px 100px;
  }
`;
