export const colors = {
  primary: '#2D9CDB',
  secondary: '#30E3E5',
  gray: '#BDBDBD',
  gray1: '#EDEDED',
  gray2: '#E0E0E0',
  gray3: '#333333',
  gray4: '#F7F7F7',
  gray5: '#F3F3F3',
  gray6: '#ebeff2',
  borderGray: '#dedede',
  graySecondary: '#4F4F4F',
  grayTertiary: '#707683',
  grayAlternative: '#828282',
  white: '#FFFFFF',
  black: '#000000',
  success: '#00C851',
  success1: '#6FCF97',
  warning: '#FFE9C9',
  warning1: '#F1BA2C',
  danger: '#C53030',
  danger1: '#E40F0F',
  info: '#C9FEFF;',
} as const;

Object.freeze(colors);
