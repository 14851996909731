import styled from 'styled-components';
import { colors } from '../../../../styles/theme/colors';

interface CategoryItemProps {
  isActive: boolean;
}

export const Container = styled.div`
  background: #ffffff;
  border-radius: 6px;
  padding: 44px 31px 0 25px;
  margin-top: 20px;
  box-shadow: 0px 4px 10px 0px rgba(6, 92, 119, 0.05);

  min-width: 339px;

  .resultContainer {
    border-radius: 100px;
    border: none;
    background: ${colors.gray6};
    padding: 0 13px;
    height: 29px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .resultContainer .resultText {
    font-family: Raleway;
    font-weight: 600;
    font-size: 12px;
    color: #4f4f4f;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  .textHeaderContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .health_title {
    font-family: Raleway;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .large_health_title {
    font-family: Raleway;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
  }
`;

export const CategoriesContainer = styled.div`
  margin-top: 60px;
  margin-bottom: 168px;

  .categoryItem {
    & + .categoryItem {
      margin-top: 10px;
    }
  }
`;

export const CategoryItem = styled.div<CategoryItemProps>`
  cursor: pointer;
  border-radius: 6px;
  padding: 9px 17px;
  background: ${({ isActive }) => (isActive ? '#daedf7' : 'transparent')};

  &:hover {
    background: #daedf7;

    span {
      color: #2d9cdb;
    }
  }

  span {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    color: ${({ isActive }) => (isActive ? '#2d9cdb' : '#707683')};
  }
`;
