import React from 'react';
import { FiInfo } from 'react-icons/fi';
import { Container, FlexRow } from './styles';

interface ManualCalibraBPProps {
  systolic: number;
  diastolic: number;
}

const ManualCalibrationBloodPressure: React.FC<ManualCalibraBPProps> = ({
  systolic,
  diastolic,
}) => {
  return (
    <Container>
      <FlexRow>
        <FiInfo color="#0480C6" size={22} />
        <p>Realizou a calibração manual no app:</p>
        <p>{`${systolic}|${diastolic}`}</p>
      </FlexRow>
    </Container>
  );
};

export default ManualCalibrationBloodPressure;
