import React from 'react';
import { GenderChart } from 'components/GenderChart';
import { Carousel } from 'components/Carousel';
import { AgeChart } from 'components/AgeChart';
import useQuery from 'hooks/query';
import { format } from 'date-fns';
import { GeneralDataContainer, HealthDataWrapper } from './styles';
import { useWellFareContext } from '../../WellFareContext';

export const GeneralData: React.FC = () => {
  const contextValue = useWellFareContext();
  const query = useQuery();
  const start = query.get('startDate');
  const end = query.get('endDate');

  const startDate = start
    ? new Date(start)
    : (() => {
        const date = new Date();
        date.setMonth(date.getMonth() - 3);
        return date;
      })();
  const endDate = end ? new Date(end) : new Date();

  return (
    <GeneralDataContainer>
      <div className="generalDataHeader">
        <div className="headerWrapper">
          <span className="generalDataTitle">Informações Gerais</span>
          {contextValue && (
            <HealthDataWrapper>
              <span>
                {contextValue.icd10.name} + {contextValue.sheet.name}
              </span>
            </HealthDataWrapper>
          )}
        </div>
        <span className="generalDataPeriod">
          Período: {startDate.toLocaleDateString()} -{' '}
          {endDate.toLocaleDateString()}
        </span>
      </div>
      <Carousel>
        <GenderChart
          startDate={format(startDate, 'yyyy-MM-dd')}
          endDate={format(endDate, 'yyyy-MM-dd')}
        />
        <AgeChart
          startDate={format(startDate, 'yyyy-MM-dd')}
          endDate={format(endDate, 'yyyy-MM-dd')}
        />
      </Carousel>
    </GeneralDataContainer>
  );
};
