import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Card } from 'styled/Card';

interface PercentProps {
  color: string;
}

export const DataSectionCardContainer = styled(Card)`
  display: flex;
  flex-direction: column;

  > h1 {
    margin-bottom: 12px;
  }

  > h6 {
    color: ${({ theme }) => theme.colors.grayTertiary};
    margin-bottom: 20px;
  }

  > div {
    flex: 1;
    overflow-y: auto;

    ::-webkit-scrollbar {
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #e3e3e3;
    }
  }
`;

export const DataSectionCardTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  overflow-y: hidden;

  td {
    padding: 13px 14px;
  }

  th {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grayTertiary};
    text-align: left;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
    padding: 13px 14px;
  }

  tr {
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray1};
    position: relative;
  }
`;

export const DataSectionCardTableDataName = styled.td`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.graySecondary};

  > span {
    font-weight: 500;
  }
`;

export const DataSectionCardTableAmount = styled.td`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.graySecondary};
`;

export const DataSectionCardTablePercent = styled.td<PercentProps>`
  font-size: 14px;
  font-weight: 600;
  color: ${({ color }) => color};

  display: flex;
`;

export const DataSectionCardLink = styled(Link)`
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
