import React from 'react';
import { CarouselDotButton as Button } from './styles';

interface CarouselDotProps {
  onClick?: () => void;
  active?: boolean;
}

export const CarouselDot: React.FC<CarouselDotProps> = ({
  onClick,
  active,
}) => {
  return <Button type="button" onClick={onClick} active={active} />;
};
