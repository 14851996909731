import styled from 'styled-components';

export const PatientTableContainer = styled.div`
  border-radius: 6px;
  padding: 22px 30px 35px;
  background: #ffffff;

  width: 100%;
`;

export const PatientTable = styled.div`
  .gridContentItem {
    padding-left: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    min-height: 64px;

    border-bottom: 1px solid #ebeff2;
  }

  .healthConditionGrid {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 64px;

    &[data-state='true'] {
      justify-content: flex-start;
      padding-left: 85px;
    }

    &[data-state='false'] {
      justify-content: flex-start;
      padding: 0 25px;
    }

    border-bottom: 1px solid #ebeff2;
  }

  .gridEyeItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 43px;
  }

  .gridContentItem .avatarContainer {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    overflow: hidden;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .gridContentItem .patientName {
    font-family: Raleway;
    font-weight: 600;
    font-size: 14px;
    color: #333333;
  }

  .gridContentItem .gridText {
    font-family: Raleway;
    font-weight: 500;
    font-size: 14px;
    color: #707683;
  }

  .healthConditionGrid .gridText {
    font-family: Raleway;
    font-weight: 500;
    font-size: 14px;
    color: #707683;
  }
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 3fr 1fr;
  width: 100%;
  background: #eaecf0;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

export const GridHeaderItem = styled.div`
  padding: 16px 25px;

  span {
    font-family: Raleway;
    font-size: 13px;
    font-weight: 600;
    color: #707683;
  }
`;

export const TableContent = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 3fr 1fr;
  background: #ffffff;
  width: 100%;
`;

export const EyeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  color: ${(props) => props.theme.colors.primary};
  font-size: 25px;
  outline: none;

  svg {
    margin: 0;
  }
`;

export const EyeButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;

  .eyeButtonText {
    font-family: Raleway;
    font-weight: 500;
    font-size: 12px;
    color: #2d9cdb;
  }
`;
