import React, { useRef, useState, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import InputWithoutTitle from '../../../../components/InputWithoutTitle';
import getUF from '../../../../utils/getUF';
import { roles } from '../../../../utils/getRole';
import { Container, GridContent, InputSelect, Button, Field } from './styles';
import { UserFiltersDTO } from '../../../../dtos/UserFiltersDTO';
import InputSwitchWithoutForm from '../../../../components/InputSwitchWithoutForm';

interface FilterProps {
  filters: UserFiltersDTO;
  setFilters(filters: UserFiltersDTO): void;
  onChangePage: (newPage: number) => any;
}

const Filter: React.FC<FilterProps> = ({
  filters,
  setFilters,
  onChangePage,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [ufOptions] = useState(getUF);

  const [isHealthProfessional, setIsHealthProfessional] =
    useState<boolean>(true);

  const handleSubmit = useCallback(() => {
    onChangePage(1);

    const type = isHealthProfessional ? 'onlyHealthProfessional' : '';
    const newFilters = {
      ...formRef?.current?.getData(),
      type,
    };
    const mergedFilters = { ...filters, ...newFilters };

    setFilters(mergedFilters);
  }, [filters, isHealthProfessional, setFilters, onChangePage]);

  const handleChangeSwitchIsHealthProfessional = useCallback(() => {
    setIsHealthProfessional((state) => !state);
  }, []);

  return (
    <Container>
      <h1>Filtrar usuário</h1>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <GridContent>
          <Field gridArea="na">
            <label>Nome</label>
            <InputWithoutTitle name="name" />
          </Field>
          <Field gridArea="em">
            <label>Email</label>
            <InputWithoutTitle name="email" />
          </Field>
          <Field gridArea="hp">
            <InputSwitchWithoutForm
              defaultValue
              change={handleChangeSwitchIsHealthProfessional}
            />
            <label>Profissional da Saúde?</label>
          </Field>
          <Field gridArea="fu">
            <label>Função</label>
            <InputSelect
              name="role"
              options={[{ value: '', label: 'Todas' }, ...roles]}
              placeholder=""
            />
          </Field>
          <Field gridArea="uf">
            <label>Estado</label>
            <InputSelect
              name="uf"
              options={[{ value: '', label: 'Todos' }, ...ufOptions]}
              placeholder=""
            />
          </Field>
          <Field gridArea="ci">
            <label>Cidade</label>
            <InputWithoutTitle name="city" />
          </Field>
          <Field isButon gridArea="bt">
            <Button>Buscar</Button>
          </Field>
        </GridContent>
      </Form>
    </Container>
  );
};

export default Filter;
