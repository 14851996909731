import React, { useMemo, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentEffect } from '../../../lib/use-current-effect';

import { usePermission } from '../../../hooks/permission';
import { FilterPatientDTO } from '../../../dtos/FilterPatientDTO';
import { filterPatients } from '../../../services/patient';
import { User } from '../../../entities/User';

import DashboardTemplate from '../../../templates/Dashboard';
import PageTree from '../../../components/PageTree';
import { ElementPageTree } from '../../../components/PageTree/types';
import Pagination from '../../../components/Pagination';
import Spinner from '../../../components/Spinner';
import CardContentNothingToDisplay from '../../../components/CardContentNothingToDisplay';
import CloudSketchesImage from '../../../assets/images/cloud-sketches.svg';

import Table from './Table';
import AlertTable from './AlertTable';
import Filter from './Filter';

import {
  Button,
  CardContentNothingToDisplayBox,
  SpinnerContainer,
} from './styles';
import PatientStatusAlertEnum from '../../../enums/PatientStatusAlertEnum';
import MainAreasEnum from '../../../enums/MainAreasEnum';

export type ListType = {
  users: User[];
  totalPages: number;
  totalPatients: number;
};

const List: React.FC = () => {
  const history = useHistory();
  const { hasPermission } = usePermission();

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<ListType>({
    users: [],
    totalPages: 0,
    totalPatients: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<FilterPatientDTO>({
    orderBy: 'registration',
    ordenationType: 'DESC',
    status: 'active',
    statusAlert: PatientStatusAlertEnum.ALL,
  });

  const canCreatePatient = useMemo(
    () => hasPermission('register_patient'),
    [hasPermission],
  );
  const pages = useMemo<ElementPageTree[]>(() => [{ name: 'Pacientes' }], []);

  useCurrentEffect(
    (isCurrent) => {
      filterPatients({
        dataFilter: filters,
        currentPage,
      })
        .then((response) => {
          if (isCurrent()) {
            setList({
              users: response.list,
              totalPages: response.totalPage,
              totalPatients: response.total,
            });
          }
        })
        .finally(() => {
          if (isCurrent()) {
            setLoading(false);
          }
        });
    },
    [currentPage, filters],
  );

  useCurrentEffect(
    (isCurrent) => {
      if (isCurrent()) {
        setLoading(true);
      }
    },
    [filters.statusAlert],
  );

  const handleCreatePatient = useCallback(
    (local) => history.push(`/${local}/register`),
    [history],
  );

  return (
    <DashboardTemplate name={MainAreasEnum.PATIENTS}>
      <PageTree pages={pages} />
      <Button
        onClick={() => handleCreatePatient('patient')}
        color="primary"
        disabled={!canCreatePatient}
      >
        Novo Paciente
      </Button>

      <Filter
        setFilters={setFilters}
        onChangePage={(page: number) => setCurrentPage(page)}
      />

      {loading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : list.users.length > 0 ? (
        <>
          {filters.statusAlert === PatientStatusAlertEnum.IN_ALERT ? (
            <AlertTable filters={filters} list={list} setFilters={setFilters} />
          ) : (
            <Table filters={filters} list={list} setFilters={setFilters} />
          )}

          <Pagination
            totalPages={list.totalPages}
            onChangePage={(page: number) => setCurrentPage(page)}
            page={currentPage}
          />
        </>
      ) : (
        <CardContentNothingToDisplayBox>
          <CardContentNothingToDisplay>
            <img src={CloudSketchesImage} alt="" />
            Nenhum dado encontrado
          </CardContentNothingToDisplay>
        </CardContentNothingToDisplayBox>
      )}
    </DashboardTemplate>
  );
};

export default List;
