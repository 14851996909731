import { GetPatientCountGroupedByGenderRequestDTO } from 'dtos/GetPatientCountGroupedByGenderRequestDTO';
import { GetPatientCountGroupedByGenderResponseDTO } from 'dtos/GetPatientCountGroupedByGenderResponseDTO';
import { api } from '../api';

export const getPatientCountGroupedByGender = async ({
  icd10,
  sheet,
  end,
  start,
}: GetPatientCountGroupedByGenderRequestDTO): Promise<GetPatientCountGroupedByGenderResponseDTO> => {
  const { data } = await api.get('/patients/countGroupedByGender', {
    params: {
      icd10,
      sheet,
      end,
      start,
    },
  });

  return data;
};
