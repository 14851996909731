import React from 'react';

import { Carousel } from 'components/Carousel';
import { GenderChart } from 'components/GenderChart';
import { AgeChart } from 'components/AgeChart';
import { format } from 'date-fns';
import { DataSectionCardContainer as Container } from '../DataSectionCard/styles';

interface DataType {
  name: string;
  amount: number;
  color: string;
}

export const DataChartCard: React.FC = () => {
  const startDate = (() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 3);
    return date;
  })();
  const endDate = new Date();

  return (
    <Container>
      <h1>Informações Gerais</h1>

      <h6>
        {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}
      </h6>

      <Carousel>
        <GenderChart
          startDate={format(startDate, 'yyyy-MM-dd')}
          endDate={format(endDate, 'yyyy-MM-dd')}
        />
        <AgeChart
          startDate={format(startDate, 'yyyy-MM-dd')}
          endDate={format(endDate, 'yyyy-MM-dd')}
        />
      </Carousel>
    </Container>
  );
};
