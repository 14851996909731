import React from 'react';
import { GeneralResult } from '../GeneralResult';
import { ResultPerPatient } from '../ResultPerPatient';
import { Questions } from '../Questions';
import { Evolution } from '../Evolution';
import { AdvancedAnalysis } from '../AdvancedAnalysis';

interface ScreenSelectorProps {
  selectedScreenId: number;
  onItemClick: (id: number) => void;
}

export const ScreenSelector = ({
  selectedScreenId,
  ...props
}: ScreenSelectorProps) => {
  switch (selectedScreenId) {
    case 1:
      return <GeneralResult {...props} />;
    case 2:
      return <ResultPerPatient />;
    case 3:
      return <Questions />;
    case 4:
      return <Evolution />;
    case 5:
      return <AdvancedAnalysis />;
    default:
      return null;
  }
};
