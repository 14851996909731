import React, { useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { useHistory } from 'react-router-dom';
import { useCurrentEffect } from 'lib/use-current-effect';
import { getSheets } from 'services/sheet';
import { Sheet } from 'entities/Sheet';
import { getAllHealthConditionFromPatients } from 'services/patient/GetAllHealthConditionFromPatients';

import * as Yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import InputAsyncSelect from 'components/InputAsyncSelect';
import InputSelectAlternative from 'components/InputSelectAlternative';
import { InputDateRange } from 'components/InputDateRange';
import {
  DataListForm as Form,
  DataListInputSection as InputSection,
  InputsCard,
  SearchButton,
  SearchIcon,
  Label,
  ConditionInputWrapper,
  SheetInputWrapper,
} from './styles';

interface Options {
  value: string;
  label: string;
}

export const DataSearchForm = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();

  const [sheets, setSheets] = useState<Options[]>();
  useCurrentEffect((isCurrent) => {
    const getData = async () => {
      const allSheets = await getSheets();

      const sheetOptions = allSheets.map((sheet: Sheet) => ({
        value: sheet.id,
        label: sheet.name,
      }));

      sheetOptions.unshift({ value: '', label: 'Todas' });
      sheetOptions.push({ value: 'noSheets', label: 'Sem fichas' });

      if (isCurrent()) {
        setSheets(sheetOptions);
      }
    };

    getData();
  }, []);

  const loadHealthConditionOptions = async (
    inputValue: string,
    _: unknown,
    additional?: { page: number },
  ) => {
    const { data: results } = await getAllHealthConditionFromPatients({
      limit: 10,
      page: additional?.page || 1,
      keyword: inputValue,
    });

    const icd10Options = results.map((option) => ({
      value: option.id,
      label: `${option.icd} - ${option.description}`,
    }));

    if (additional?.page === 1) {
      icd10Options.unshift({ label: 'Todas', value: '' });
    }

    return {
      options: icd10Options,
      hasMore: results.length > 0,
      additional: { page: (additional?.page ? additional.page : 1) + 1 },
    };
  };

  const onSubmit = async (data: unknown) => {
    try {
      const schema = Yup.object().shape({
        sheet: Yup.string(),
        icd10: Yup.string(),
        period: Yup.object({
          startDate: Yup.date().required('Escolha um período'),
          endDate: Yup.date().max(new Date()).required('Escolha um período'),
        }).required('Escolha um período'),
      });

      const parsed = await schema.validate(data, {
        abortEarly: false,
      });
      const {
        period: { startDate, endDate },
        icd10,
        sheet,
      } = parsed;

      history.push({
        pathname: '/administration/well_fare',
        search: new URLSearchParams({
          ...(icd10 && { icd10 }),
          ...(sheet && { sheet }),
          startDate: startDate.toDateString(),
          endDate: endDate.toDateString(),
        }).toString(),
      });

      // search logic
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);

        if (errors['period.startDate'] || errors['period.endDate']) {
          errors.period =
            errors['period.startDate'] || errors['period.endDate'];
        }
        formRef.current?.setErrors(errors);
      }
    }
  };
  return (
    <InputSection>
      <InputsCard>
        <h1>Dados de Saúde</h1>

        <Form ref={formRef} onSubmit={onSubmit}>
          <ConditionInputWrapper>
            <Label>Condição de saúde</Label>
            <InputAsyncSelect
              name="icd10"
              cacheOptions
              loadOptions={loadHealthConditionOptions}
              placeholder=""
            />
          </ConditionInputWrapper>
          <SheetInputWrapper>
            <Label>Fichas de saúde</Label>
            <InputSelectAlternative
              name="sheet"
              options={sheets}
              placeholder=""
            />
          </SheetInputWrapper>
          <div
            style={{
              display: 'flex',
              width: '100%',
              gap: 250,
            }}
          >
            <InputDateRange name="period" label="Período" />
            <SearchButton>
              <SearchIcon />
              Buscar
            </SearchButton>
          </div>
        </Form>
      </InputsCard>
    </InputSection>
  );
};
